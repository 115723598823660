<template lang="html">
  <section>
    <b-navbar v-if="$smallScreen" class="navbar-light bg-light shadow fixed-top header-nav mobile">
      <b-nav class="w-100 d-flex justify-content-between align-items-center">
        <b-nav-item v-if="user.name" class="p-0">
          <div class="btn-icon text-muted" @click="toggleSidebar">
            <font-awesome-icon v-if="sidebar === SIDEBAR.EXPANDED" icon="times" size="2x" fixed-width />
            <font-awesome-icon v-else icon="bars" size="2x" fixed-width />
          </div>
        </b-nav-item>
        <b-nav-item class="mx-auto" to="/">
          <img src="/images/dual-branding.svg" width="200" height="auto" alt="Vehicare Fleet Solutions" />
        </b-nav-item>
        <b-nav-item v-if="isAuthenticated">
          <div class="btn-icon text-muted" @click="logout">
            <font-awesome-icon icon="sign-out-alt" size="2x" fixed-width />
          </div>
        </b-nav-item>
      </b-nav>
    </b-navbar>

    <b-navbar v-else class="d-flex flex-wrap navbar-light bg-light fixed-top shadow">
      <img src="/images/dual-branding.svg" width="300" height="auto" alt="Vehicare Fleet Solutions" />
      <h3 v-if="$environment">
        <b-badge variant="danger" class="ml-4">{{ $environment }}</b-badge>
      </h3>
      <b-nav v-if="isAuthenticated" class="ml-auto">
        <b-nav-item disabled>
          <div id="username" class="text-nowrap">
            <span class="no-link">Welcome {{ user.name }}</span>
          </div>
        </b-nav-item>
        <b-nav-item>
          <span class="text-nowrap link">
            <a id="logout" @click="logout">Log out</a>
          </span>
        </b-nav-item>
      </b-nav>
      <b-nav v-else class="ml-auto">
        <b-nav-item>
          <span class="text-nowrap link">
            <a id="login" @click="login">Log in</a>
          </span>
        </b-nav-item>
      </b-nav>
    </b-navbar>
  </section>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
import { UserGetters } from '@/shared/store/user/types';
import { ConfigGetters } from '@/shared/store/config/types';
import { RootGetters, RootMutations } from '@/shared/store/types';
import { SIDEBAR } from '@/shared/store/state';
// helpers
import timeZoneHelper from '@/shared/helpers/time-zone-helper';

export default {
  name: 'AppHeader',
  data() {
    return {
      SIDEBAR: SIDEBAR
    };
  },
  computed: {
    ...mapGetters({
      config: ConfigGetters.GET_CONFIG,
      user: UserGetters.GET_USER_PROFILE,
      isAuthenticated: UserGetters.IS_AUTHENTICATED
    }),
    userTimeZone() {
      return timeZoneHelper.userTimeZone();
    },
    sidebar: {
      get() {
        return this.$store.getters[RootGetters.GET_SIDEBAR];
      },
      set(value) {
        this.$store.commit(RootMutations.SET_SIDEBAR, value);
      }
    }
  },
  methods: {
    async login() {
      this.$msal.signIn();
    },
    logout: async function () {
      this.$router.push({ name: 'Login', params: { logout: true } });
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    }
  }
};
</script>
