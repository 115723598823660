import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, PartsOrderGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, PartsOrderGetters);

export default {
  [_getters.GET_PARTS_ORDER]: state => {
    return state.partsOrder;
  },
  [_getters.GET_DB_PARTS_ORDER]: state => {
    return state.dbPartsOrder;
  },
  // [_getters.GET_ALL_OPS_EMPLOYEES]: state => {
  //   return state.allOpsEmployees;
  // },
  // [_getters.GET_MATERIALS_EMPLOYEES]: state => {
  //   return state.materialsEmployees;
  // },
  [_getters.GET_ORDER_PARTS]: state => {
    return state.partsOrder?.requestParts;
  },
  [_getters.GET_ORDERED_PARTS]: state => {
    return state.requestedParts;
  },
  [_getters.GET_PARTS_ORDER_SEARCH_RESULTS]: state => {
    return state.partsOrderSearchResults;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return (
      JSON.stringify(state.partsOrder, replacer) !== JSON.stringify(state.dbPartsOrder, replacer) ||
      JSON.stringify(state.partsOrder.parts, replacer) !== JSON.stringify(state.dbPartsOrder.parts, replacer)
    );
  }
};
