export const namespace = 'subletInvoice/';

export const SubletInvoiceGetters = {
  GET_SUBLET_INVOICE_STATUSES_DISPLAY_LIST: namespace + 'getSubletInvoiceStatusesDisplayList',
  GET_SUBLET_INVOICE_STATUSES: namespace + 'getSubletInvoiceStatuses',
  GET_SUBLET_INVOICE_ITEM_CATEGORIES: namespace + 'getSubletInvoiceItemCategories',
  GET_SUBLET_INVOICES: namespace + 'getInvoices',
  GET_REVIEW_SUBLET_INVOICES: namespace + 'getReviewSubletInvoices',
  GET_SEARCH_SUBLET_INVOICES: namespace + 'getSearchSubletInvoices',
  GET_FILTERED_SUBLET_INVOICES: namespace + 'getFilteredInvoices',
  GET_SUBLET_INVOICE: namespace + 'getInvoice',
  GET_DB_SUBLET_INVOICE: namespace + 'getDBInvoice',
  GET_IS_FREEZE_PDF: namespace + 'isFreezePdf',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const SubletInvoiceActions = {
  FETCH_SUBLET_INVOICE_LOOKUPS: namespace + 'fetchSubletInvoiceLookups',
  SEARCH_SUBLET_INVOICES: namespace + 'searchSubletInvoices',
  FETCH_SUBLET_INVOICE: namespace + 'fetchInvoice',
  SAVE_SUBLET_INVOICE: namespace + 'saveSubletInvoice',
  APPROVE_SUBLET_INVOICE: namespace + 'approveInvoice',
  REJECT_SUBLET_INVOICE: namespace + 'rejectInvoice',
  ADD_NEW_SUBLET_LINE_ITEM: namespace + 'addNewSubletLineItem',
  REMOVE_SUBLET_LINE_ITEM: namespace + 'removeSubletLineItem',
  REMOVE_SUBLET_INVOICE: namespace + 'removeSubletInvoice',
  VALIDATE_SUBLET_INVOICE: namespace + 'validateSubletInvoice',
  DOWNLOAD_SUBLET_INVOICE_PDF: namespace + 'downloadPDF'
};

export const SubletInvoiceMutations = {
  SET_SUBLET_INVOICE_LOOKUPS: namespace + 'setSubletInvoiceLookups',
  SET_SUBLET_INVOICES: namespace + 'setInvoices',
  SET_SEARCH_SUBLET_INVOICES: namespace + 'setSearchSubletInvoices',
  SET_SUBLET_INVOICE: namespace + 'setInvoice',
  SET_DB_SUBLET_INVOICE: namespace + 'setDBInvoice',
  SET_FILTERED_SUBLET_INVOICES: namespace + 'setFilteredInvoices',
  SET_COMMON_PROP: namespace + 'setCommonProp',
  SET_LINEITEM_PROP: namespace + 'setLineItemProp',
  SET_DB_LINEITEM_PROP: namespace + 'setDBLineItemProp',
  REMOVE_SUBLET_INVOICE: namespace + 'removeSubletInvoice',
  SET_NEW_SUBLET_LINE_ITEM: namespace + 'setNewSubletLineItem',
  REMOVE_SUBLET_LINE_ITEM: namespace + 'removeSubletLineItem',
  IS_FREEZE_PDF: namespace + 'isFreezePdf'
};
