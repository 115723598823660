import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, SubletInvoiceMutations } from './types';
import Cloner from '@/shared/helpers/cloner';
import Vue from 'vue';

const _mutations = namespaceHelper.removeNamespace(namespace, SubletInvoiceMutations);

export default {
  [_mutations.SET_SUBLET_INVOICE_LOOKUPS](state, result) {
    state.subletInvoiceStatuses = result.lookups.subletInvoiceStatuses;
    state.subletInvoiceItemCategories = result.lookups.subletInvoiceItemCategories;
  },
  [_mutations.SET_FIRE_INVOICE_UI_CONFIG](state, uiConfig) {
    state.uiConfig = uiConfig;
  },
  [_mutations.SET_SUBLET_INVOICES](state, results) {
    var type = results.parameters.category;
    state.invoices[type] = results.searchResults;
  },
  [_mutations.SET_SUBLET_INVOICE](state, result) {
    state.invoice = result;
    state.dbInvoice = Cloner.deepClone(result);
  },
  [_mutations.SET_DB_SUBLET_INVOICE](state, result) {
    state.dbInvoice = result;
  },
  [_mutations.SET_SEARCH_SUBLET_INVOICES](state, result) {
    state.searchSubletInvoices = result;
  },
  [_mutations.SET_FILTERED_SUBLET_INVOICES](state, list) {
    state.filteredInvoices = list;
  },
  [_mutations.SET_COMMON_PROP](state, { key, value }) {
    Vue.set(state.invoice.subletInvoice, key, value);
  },
  [_mutations.SET_LINEITEM_PROP](state, { index, key, value }) {
    Vue.set(state.invoice.subletInvoiceItems[index], key, value);
  },
  [_mutations.SET_DB_LINEITEM_PROP](state, { index, key, value }) {
    Vue.set(state.dbInvoice.subletInvoiceItems[index], key, value);
  },
  [_mutations.REMOVE_SUBLET_INVOICE](state, index) {
    Vue.delete(state.filteredInvoices, index);
  },
  [_mutations.SET_NEW_SUBLET_LINE_ITEM](state, params) {
    var newItem = {
      itemKey: params.itemKey,
      documentKey: params.documentKey,
      description: null,
      category: null,
      quantity: null,
      qtyFlag: 0,
      unit: null,
      UnitPrice: null,
      UnitPriceFlag: 0,
      ProductCode: null,
      AmtPrice: null,
      AmtPriceFlag: 0
    };

    state.invoice.subletInvoiceItems.push(newItem);
    state.dbInvoice.subletInvoiceItems.push(newItem);
  },
  [_mutations.REMOVE_SUBLET_LINE_ITEM](state, index) {
    Vue.delete(state.invoice.subletInvoiceItems, index);
    Vue.delete(state.dbInvoice.subletInvoiceItems, index);
  },
  [_mutations.IS_FREEZE_PDF](state, isFreezePdf) {
    state.isFreezePdf = isFreezePdf;
  }
};
