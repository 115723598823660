import { RootMutations } from './types';

export default {
  [RootMutations.SET_SIDEBAR](state, sidebar) {
    state.sidebar = sidebar;
  },

  [RootMutations.SET_ONLINE](state, status) {
    state.online = status;
  },
  [RootMutations.SET_SCREEN_WIDTH](state, width) {
    state.screenWidth = width;
  },
  [RootMutations.SET_URL](state, url) {
    state.url = url;
  },
  [RootMutations.SET_SAVE_SHORTCUT](state, value) {
    state.saveShortcut = value;
  }
};
