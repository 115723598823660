import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, PartsOrderMutations } from './types';
import { getDefaultState } from './state';
import Cloner from '@/shared/helpers/cloner';

const _mutations = namespaceHelper.removeNamespace(namespace, PartsOrderMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_PARTS_ORDER](state, partsOrder) {
    state.partsOrder = partsOrder[0];
    state.dbPartsOrder = Cloner.deepClone(partsOrder[0]);
  },
  [_mutations.SET_PARTS_ORDER_SEARCH_RESULTS](state, partsOrderSearchResults) {
    state.partsOrderSearchResults = partsOrderSearchResults;
  }
};
