import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, DvirMutations } from './types';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, DvirMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_DVIR_SEARCH_RESULTS](state, results) {
    state.dvirSearchResults = results;
  },
  [_mutations.SET_DVIRS_COUNT](state, results) {
    state.dvirCountFetchDate = new Date();
    state.dvirsCount = results;
  }
};
