import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, SubletInvoiceGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, SubletInvoiceGetters);

export default {
  [_getters.GET_SUBLET_INVOICE_STATUSES_DISPLAY_LIST]: state => {
    let array = [];
    state.subletInvoiceStatuses.forEach(status => {
      if (status.displayDescription && array.indexOf(status.displayDescription) == -1) {
        array.push(status.displayDescription);
      }
    });
    return array;
  },
  [_getters.GET_SUBLET_INVOICE_STATUSES]: state => {
    return state.subletInvoiceStatuses;
  },
  [_getters.GET_SUBLET_INVOICE_ITEM_CATEGORIES]: state => {
    return state.subletInvoiceItemCategories;
  },
  [_getters.GET_INVOICES]: state => invoiceType => {
    return state.invoices[invoiceType];
  },
  [_getters.GET_SEARCH_SUBLET_INVOICES]: state => {
    return state.searchSubletInvoices ? state.searchSubletInvoices : [];
  },
  [_getters.GET_REVIEW_SUBLET_INVOICES]: state => {
    return state.invoices.review ? state.invoices.review : [];
  },
  [_getters.GET_FILTERED_SUBLET_INVOICES]: state => {
    return state.filteredInvoices;
  },
  [_getters.GET_SUBLET_INVOICE]: state => {
    return state.invoice;
  },
  [_getters.GET_DB_SUBLET_INVOICE]: state => {
    return state.dbInvoice;
  },
  [_getters.GET_IS_FREEZE_PDF]: state => {
    return state.isFreezePdf;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.invoice, replacer) !== JSON.stringify(state.dbInvoice, replacer);
  }
};
