import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/shared/styles/styles.css';
import '@/shared/styles/ie-only.css';
import 'vue-select/dist/vue-select.css';

Vue.use(BootstrapVue);
