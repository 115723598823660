<template>
  <div>
    <b-alert id="OfflineAlert" :show="!online" variant="secondary" class="m-0 py-1 px-2 rounded-0 offline-alert">
      <cloud-offline width="16" height="16" class="mx-1 my-auto" />
      <div v-if="$isIOS">
        OFFLINE: iOS is not supported for offline mode. Some features may not work and changes can be lost.
      </div>
      <div v-else>OFFLINE: Changes will be stored locally until network is restored.</div>
    </b-alert>
    <b-toast id="notification-toast" variant="info" solid toaster="b-toaster-top-center" :title="title" no-auto-hide>
      <div class="d-flex flex-column">
        <b-progress
          v-if="progress"
          :value="ongoing"
          :max="total"
          :variant="progressVariant"
          :animated="ongoing < total"
        />
        <div v-if="ongoing < total">{{ ongoingMessage }}</div>
        <div v-else>{{ message }}</div>
        <div v-if="hasSyncError" class="pt-3 note">
          <p v-show="errorObj.status">{{ errorObj.status }} - {{ errorObj.statusText }}</p>
          {{ errorObj.error }}
          <p>Sync will be attempted again leave app open, if problem continues contact an administrator.</p>
        </div>
      </div>
    </b-toast>

    <b-modal id="AppUpdateModal" ref="AppUpdateModal" title="Update Available" centered no-close-on-backdrop>
      <p>A new version of VAMS is available. Click Refresh to update. Unsaved data will be lost.</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" :disabled="refreshing" @click="refreshApp">
          <div v-show="waitForRefresh || refreshing">
            <b-spinner small></b-spinner>
            Refreshing...
          </div>
          <div v-show="!waitForRefresh && !refreshing">Refresh</div>
        </b-button>
        <b-button size="sm" @click="cancel">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// components
import CloudOfflineIcon from '@/shared/svg/CloudOfflineIcon';
// vuex
import { RootGetters } from '@/shared/store/types';
import { mapGetters } from 'vuex';

export default {
  name: 'VamsNotifications',
  components: {
    'cloud-offline': CloudOfflineIcon
  },
  data() {
    return {
      title: 'Offline Mode',
      ongoingMessage: 'Syncing...',
      message: 'Complete',
      progress: false,
      ongoing: 0,
      total: -1,
      waitForRefresh: false,
      refreshing: false,
      registration: null,
      updateExists: false,
      hasSyncError: false,
      errorObj: {},
      showErrorDetails: false
    };
  },
  computed: {
    ...mapGetters({
      online: RootGetters.GET_ONLINE
    }),
    progressVariant() {
      if (this.hasSyncError) return 'danger';
      else return this.ongoing < this.total ? 'info' : 'success';
    }
  },
  created() {
    navigator.serviceWorker.addEventListener('message', this.handleEvent);
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  beforeDestroy() {
    navigator.serviceWorker.removeEventListener('message', this.handleEvent);
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.$bvModal.show('AppUpdateModal');
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.waitForRefresh = true;
      var controller = new AbortController();
      controller.abort();
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      return;
    },
    handleEvent(event) {
      const message = event.data;
      if (message.type == 'queue-update') {
        this.trackEvent('background-sync', message);
        if (message.starting) {
          this.progress = true;
          this.showSyncToast(message);
        }
        if (message.response && (message.response.error || message.response.status >= 400)) {
          this.hasSyncError = true;
          this.message = 'An error has occuried during Inspection sync.';
          this.ongoing = this.total;
          this.errorObj = {
            status: message.response.status,
            statusText: message.response.statusText,
            error: message.response.error
          };
          return;
        }

        this.total = message.total;
        this.ongoing = message.ongoing;
      }
    },
    showSyncToast(message) {
      this.hasSyncError = false;
      if (message.queue == 'vams-update-inspections') {
        this.title = 'Inspections';
        this.ongoingMessage = 'Syncing inspections that were saved offline...';
        this.message = 'Inspection sync complete!';
      }
      this.$root.$bvToast.show('notification-toast');
    },
    trackEvent(name, obj) {
      if (this.$appInsights) {
        this.$appInsights.trackEvent({ name }, obj);
      }
    }
  }
};
</script>
