import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, PartsOrderActions, PartsOrderMutations } from './types';
import PartsOrderService from '@/shared/services/PartsOrderService';

const _actions = namespaceHelper.removeNamespace(namespace, PartsOrderActions);
const _mutations = namespaceHelper.removeNamespace(namespace, PartsOrderMutations);

export default {
  async [_actions.SEND_PARTS_ORDER](_, partsOrder) {
    const response = await PartsOrderService.sendPartsOrder(partsOrder);
    return response.data;
  },
  async [_actions.SAVE_PARTS_ORDER](_, partsOrder) {
    await PartsOrderService.savePartsOrder(partsOrder);
  },
  async [_actions.FETCH_ORDERED_PARTS]({ commit }, salesOrderId) {
    const response = await PartsOrderService.fetchOrderedParts(salesOrderId);
    commit(_mutations.SET_ORDERED_PARTS, response.data);
  },
  async [_actions.FETCH_PARTS_ORDER]({ commit }, partsOrderId) {
    const response = await PartsOrderService.fetchPartsOrder(partsOrderId);
    commit(_mutations.SET_PARTS_ORDER, response.data);
    return response.data[0];
  },
  async [_actions.RESET_PARTS_ORDER_FORM]({ commit }) {
    commit(_mutations.SET_PARTS_ORDER, {
      partsOrderTypeId: null,
      dateDeliveryBy: null,
      requestParts: [],
      unitId: null
    });
    commit(_mutations.ADD_ORDER_PART);
    commit(_mutations.ADD_DB_ORDER_PART);
  },
  async [_actions.FETCH_ALL_OPS_EMPLOYEES]({ commit }) {
    const response = await PartsOrderService.fetchAllOpsEmployees();
    commit(_mutations.SET_ALL_OPS_EMPLOYEES, response.data);
  },
  async [_actions.FETCH_MATERIALS_EMPLOYEES]({ commit }) {
    const response = await PartsOrderService.fetchMaterialsEmployees();
    commit(_mutations.SET_MATERIALS_EMPLOYEES, response.data);
  },
  async [_actions.SEARCH_PARTS_ORDERS]({ commit }, parameters) {
    const response = await PartsOrderService.searchPartsOrders(parameters);
    commit(_mutations.SET_PARTS_ORDER_SEARCH_RESULTS, response.data);
  },
  async [_actions.EXPORT_PARTS_ORDER_SEARCH_RESULTS](_, parameters) {
    const response = await PartsOrderService.exportPartsOrderSearchResults(parameters);
    return response.data;
  },
  async [_actions.SEND_PAYMENT_REQUEST]({ commit }, params) {
    const partOrderId = params.orderId;
    const ePayRequest = {
      ePayEmail: params.ePayEmail,
      requestedAmount: params.requestedAmount,
      branchId: params.branchId
    };
    const partOrderResponse = await PartsOrderService.sendPaymentRequest({
      partOrderId,
      ePayRequest
    });
    commit(_mutations.SET_PARTS_ORDER, partOrderResponse.data);
    return partOrderResponse.data[0];
  },
  async [_actions.SEND_CARD_PAYMENT_REQUEST]({ commit }, params) {
    const partOrderId = params.orderId;
    const ePayRequest = {
      ePayEmail: params.ePayEmail,
      requestedAmount: params.requestedAmount,
      branchId: params.branchId
    };
    const partOrderResponse = await PartsOrderService.sendCardPaymentRequest({
      partOrderId,
      ePayRequest
    });
    commit(_mutations.SET_PARTS_ORDER, partOrderResponse.data);
    return partOrderResponse.data[0];
  }
};
