<template>
  <div>
    <b-navbar
      v-if="$smallScreen"
      class="navbar-light bg-light shadow fixed-top header-nav mobile"
      style="z-index: 1039"
    >
      <b-nav class="w-100 d-flex justify-content-between align-items-center">
        <b-nav-item class="p-1">
          <div class="btn-icon" @click="goBack()">
            <font-awesome-icon icon="arrow-left" fixed-width />
            Back
          </div>
        </b-nav-item>
      </b-nav>
    </b-navbar>

    <b-card class="page-card">
      <h2 class="card-header">Inspection</h2>
      <b-overlay :show="loading" :opacity="0.6">
        <b-row no-gutters class="pb-1">
          <b-col cols="12" lg="5">
            <!-- Branch -->
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="5"
              label="Branch:"
              label-for="branchId"
              :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
            >
              <template #label>
                Branch:
                <span class="required-asterisk">*</span>
              </template>
              <b-input-group>
                <v-select
                  id="branchId"
                  v-model="$v.branchId.$model"
                  :class="$v.branchId.$error ? 'is-invalid' : ''"
                  :options="myBranches"
                  :filter-by="filterByBranch"
                  :reduce="x => x.branchId"
                  label="branchName"
                  :clearable="false"
                  select-on-tab
                >
                  <template #option="branch">
                    <span class="text-uppercase">{{ branch.branchId }} - {{ branch.branchName }}</span>
                  </template>
                  <template #selected-option="branch">
                    <span class="text-uppercase">{{ branch.branchId }} - {{ branch.branchName }}</span>
                  </template>
                </v-select>
              </b-input-group>
            </b-form-group>

            <!-- Inspection -->
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="5"
              label="Inspection:"
              label-for="inspectionId"
              :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
            >
              <template #label>
                Inspection:
                <span class="required-asterisk">*</span>
              </template>
              <b-input-group>
                <v-select
                  id="inspectionId"
                  v-model="$v.inspectionId.$model"
                  :class="$v.inspectionId.$error ? 'is-invalid' : ''"
                  :options="inspectionList"
                  :reduce="x => x.inspectionId"
                  :clearable="false"
                  label="name"
                  select-on-tab
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row no-gutters class="pb-2">
          <b-col cols="12" lg="5"></b-col>
        </b-row>

        <b-row no-gutters class="pb-2">
          <b-col cols="12" lg="5">
            <!-- Create and Cancel -->
            <b-form-group label-cols-lg="5" label-cols-sm="12">
              <b-button
                :class="`${$smallScreen ? 'w-100' : 'mr-3'}`"
                :disabled="stage == STAGES.CREATING"
                variant="primary"
                @click="createInspection()"
              >
                <div v-show="stage == STAGES.CREATING">
                  <b-spinner small></b-spinner>
                  Starting...
                </div>
                <div v-show="stage != STAGES.CREATING">Start Inspection</div>
              </b-button>
              <b-button v-if="!$smallScreen" variant="secondary" @click="goBack">Cancel</b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
// components
import vSelect from 'vue-select';
// vuex
import { UserGetters } from '@/shared/store/user/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { CustomerActions, CustomerGetters } from '@/shared/store/customer/types';
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { InspectionActions, InspectionGetters, InspectionMutations } from '@/shared/store/inspection/types';
import { STAGES } from '@/shared/store/inspection/state';
// helpers
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

export default {
  Name: 'InspectionCreatePage',
  components: {
    'v-select': vSelect
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from;
      next();
    });
  },
  data() {
    return {
      STAGES: STAGES,
      loading: false,
      from: { name: 'UnitSearch' },
      branchId: null,
      inspectionId: null
    };
  },
  validations: {
    branchId: {
      required
    },
    inspectionId: {
      required
    }
  },
  computed: {
    ...mapGetters({
      stage: InspectionGetters.GET_STAGE,
      unit: UnitGetters.GET_UNIT,
      customer: CustomerGetters.GET_CUSTOMER
    }),
    inspectionList() {
      const inpsections = this.$store.getters[LookupGetters.GET_INSPECTION_LIST].filter(
        i =>
          i.standalone &&
          (i.billToCustomerIds == null ||
            i.billToCustomerIds.length === 0 ||
            i.billToCustomerIds.includes(this.customer?.billToCustomer?.customerId))
      );
      return inpsections;
    },
    myBranches() {
      return this.$store.getters[UserGetters.GET_USER_PROFILE].employeeBranches;
    },
    unitId() {
      return this.$route.params.unitId;
    }
  },
  async created() {
    this.loading = true;
    try {
      this[InspectionMutations.SET_STAGE](STAGES.CREATE);
      await this[UnitActions.FETCH_UNIT](this.unitId);
      await this[CustomerActions.FETCH_CUSTOMER](this.unit.customerId);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([InspectionActions.CREATE_INSPECTION, UnitActions.FETCH_UNIT, CustomerActions.FETCH_CUSTOMER]),
    ...mapMutations([InspectionMutations.SET_STAGE]),
    async switchStage(newStage) {
      return await this.$store.dispatch(InspectionActions.SWITCH_STAGE, { newStage, that: this });
    },
    goBack() {
      this.$router.push(this.from);
    },
    filterByBranch(option, label, search) {
      return (
        option.branchId.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.branchName.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    async createInspection() {
      // TODO handle offline creation
      if (this.stage >= STAGES.CREATING) return;
      const switched = await this.switchStage(STAGES.CREATING);
      if (!switched) return;
      const inspection = {
        unitId: this.unitId,
        branchId: this.branchId,
        inspectionId: this.inspectionId
      };

      try {
        const unitInspection = await this[InspectionActions.CREATE_INSPECTION](inspection);
        SuccessService.createSuccessToast(this.$root, `${unitInspection.name} started.`);
        this.$router.replace({
          path: `/inspections/${unitInspection.unitInspectionId}`
        });
      } catch (error) {
        const errorMessage = `Error starting Inspection.`;
        ErrorService.createErrorToast(this, errorMessage);
        this[InspectionMutations.SET_STAGE](STAGES.CREATE);
        throw Error(errorMessage);
      }
    }
  }
};
</script>
