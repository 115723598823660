<template>
  <div>
    <h5 v-if="!isInvoice && !isInvoiceHistory">Notes</h5>
    <h6 class="text-uppercase">{{ title }}</h6>

    <b-row v-if="!$isCustomer && isServiceOrder">
      <b-button-group class="so-status order-neg1 mr-2">
        <b-form-radio-group
          v-model="filterOptionSelected"
          :options="filterOptions"
          buttons
          button-variant="secondary"
        ></b-form-radio-group>
      </b-button-group>
      <b-col>
        <span class="required-legend float-right p-0">* Required</span>
      </b-col>
    </b-row>

    <b-overlay :show="loading" :opacity="0.6">
      <b-table
        striped
        no-border-collapse
        sticky-header="42vh"
        :items="filteredNotes"
        :fields="fields"
        class="align-middle mt-3 border"
        show-empty
        :empty-text="`There are no notes for this ${label}`"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(note)="{ item }">
          <b-row v-if="item.noteType === 'PartsRequest'" no-gutters>
            <b-col sm="2" class="col-head text-notification">PARTS REQUEST</b-col>
          </b-row>
          <b-row v-else-if="item.internal" no-gutters>
            <b-col sm="2" class="col-head text-success">
              INTERNAL
              <img src="/images/internal.svg" alt="Internal" />
            </b-col>
          </b-row>
          <b-row no-gutters class="pb-1">
            <b-col sm="2" class="col-head">FROM:</b-col>
            <b-col>
              <employee-display :employee="{ emailAddress: item.fromEmail, name: item.fromName }" />
            </b-col>
          </b-row>
          <b-row v-if="item.isWorkflow" no-gutters class="pb-1">
            <b-col sm="2" class="col-head">ASSIGNED TO:</b-col>
            <b-col>
              <employee-display :employee="getEmployee(item.assignedTo)" />
            </b-col>
          </b-row>
          <b-row
            v-if="
              !item.isWorkflow &&
              item.noteType != 'Invoice' &&
              item.noteType != 'InvoiceRejection' &&
              item.noteType != 'SubletInvoice'
            "
            no-gutters
            class="pb-1"
          >
            <b-col sm="2" class="col-head">TO:</b-col>
            <b-col><employee-display :employee="toRecipients(item.recipients)" /></b-col>
          </b-row>
          <b-row
            v-if="item.isWorkflow || item.noteType === 'ApprovalRequest' || item.noteType === 'ApprovalResponse'"
            no-gutters
            class="pb-1"
          >
            <b-col sm="2" class="col-head">NOTIFIED:</b-col>
            <b-col><employee-display :employee="notifiedRecipients(item.recipients)" /></b-col>
          </b-row>

          <b-row no-gutters class="pb-1">
            <b-col sm="2" class="col-head">DATE:</b-col>
            <b-col>{{ item.datePosted | dateTime }}</b-col>
          </b-row>

          <b-row no-gutters>
            <b-col sm="2">
              <div v-if="item.noteType === 'InvoiceRejection'" class="col-head-red">REASON FOR REJECTION:</div>
            </b-col>
            <b-col>
              <div v-if="item.noteType === 'ApprovalRequest'">REQUEST FOR APPROVAL on {{ serviceOrderId }}</div>
              {{ item.message }}
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-overlay>
    <div v-if="!isInvoiceHistory">
      <div v-if="$isCustomer || branchIsAccessible || !isServiceOrder">
        <h5>
          <span v-if="!isInvoice">Create</span>
          New Note
        </h5>
        <b-row v-if="!$isCustomer && !(isPartsRequest || isInvoice)" no-gutters>
          <b-col>
            <b-form-checkbox
              v-model="isInternal"
              class="float-right"
              :disabled="!canBeExternal || hasCustomersSelected"
            >
              Internal Only
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-form-group
          v-if="$isCustomer && (!isInvoice || !isPartsRequest)"
          label="Recipients:"
          label-class="col-head"
          class="required-placeholder pb-2"
        >
          <b-input-group>
            <employee-select v-model="vcpRecipients" :options="vcpRecipientList" multiple />
          </b-input-group>
          <warning-alert v-show="noRecipientsWarning" full-width>
            No recipients selected. Click Post again to add note without recipients.
          </warning-alert>
        </b-form-group>

        <b-form-group
          v-if="!$isCustomer && !isInvoice"
          :label="isPartsRequest ? 'Recipients:' : 'Internal Recipients:'"
          label-class="col-head"
          label-for="internal-recipients"
          class="required-placeholder pb-2"
        >
          <b-input-group>
            <employee-select
              v-model="internalRecipients"
              :options="isPartsRequest ? partsRequestRecipientsList : employeeList"
              :no-options-text="
                isServiceOrder || $isCustomer || isPartsRequest ? null : 'No recipients available without unit location'
              "
              multiple
            />
          </b-input-group>
          <warning-alert v-show="noRecipientsWarning" full-width>
            No recipients selected. Click Post again to add note without recipients.
          </warning-alert>
        </b-form-group>

        <b-form-group
          v-if="!$isCustomer && isCsrOrCsm && !(isPartsRequest || isInvoice)"
          label="Customers:"
          label-class="col-head"
          label-for="external-recipients"
          class="required-placeholder pb-2"
        >
          <b-input-group>
            <employee-select
              id="external-recipients"
              v-model="externalRecipients"
              multiple
              :options="!$isCustomer ? vcpUserList : vcpRecipientList"
              :disabled="!canBeExternal || isInternal"
              no-options-text="No customers are set up in VCP."
            />
          </b-input-group>
          <div v-if="isServiceOrder && !uiConfig.showOpenSOs" class="note float-right">
            Notes on open SOs are not available to customers.
          </div>
        </b-form-group>

        <b-form-group label="Message: " label-class="col-head" label-for="note-message" class="pb-2">
          <b-input-group>
            <b-textarea
              id="note-message"
              v-model="message"
              class="overflow-auto rounded"
              :class="$v.message.$error && !$v.message.required ? 'is-invalid' : ''"
              size="sm"
              rows="1"
              max-rows="4"
            />
            <span class="required-asterisk">*</span>
          </b-input-group>
        </b-form-group>

        <b-button variant="primary" :disabled="posting" @click="postNote()">
          <div v-show="posting">
            <b-spinner small></b-spinner>
            Posting...
          </div>
          <div v-show="!posting">Post</div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { NoteGetters, NoteActions, NoteMutations } from '@/shared/store/note/types';
import { CustomerGetters } from '@/shared/store/customer/types';
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { UserGetters } from '@/shared/store/user/types';
import { UnitGetters } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import WarningAlertComponent from '@/shared/components/WarningAlertComponent';
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
import { required } from 'vuelidate/lib/validators';
import { PartsRequestActions, PartsRequestGetters } from '@/shared/store/parts-request/types';
import { LookupActions } from '@/shared/store/lookup/types';

export default {
  name: 'Notes',
  components: {
    'warning-alert': WarningAlertComponent,
    'employee-select': EmployeeSelectComponent,
    'employee-display': EmployeeDisplayComponent
  },
  props: {
    isServiceOrder: {
      type: Boolean,
      default: () => false
    },
    isPartsRequest: {
      type: Boolean,
      default: () => false
    },
    isInvoice: {
      type: Boolean,
      default: () => false
    },
    isInvoiceHistory: {
      type: Boolean,
      default: () => false
    },
    label: String(),
    title: String(),
    path: {
      type: String(),
      default() {
        return this.$route.path;
      }
    },
    branch: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      fields: [
        { key: 'index', label: '#', tdClass: 'align-middle', thStyle: 'width: 20px' },
        { key: 'note', label: 'Posted', tdClass: 'align-middle' }
      ],
      filterOptions: ['All', 'Workflow Only'],
      filterOptionSelected: 'All',
      loading: false,
      posting: false,
      noRecipientsWarning: false,
      allowOverride: true
    };
  },
  validations: {
    message: {
      required
    }
  },
  computed: {
    ...mapState('serviceOrder', ['estimorder']),
    ...mapGetters([NoteGetters.GET_NOTES, UserGetters.AUTHORIZE_ROLE]),
    ...mapGetters({
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      branchId: ServiceOrderGetters.GET_BRANCH_ID,
      vcpUserList: CustomerGetters.GET_VCP_USER_LIST,
      employeeList: LookupGetters.GET_EMPLOYEE_LIST,
      newNote: NoteGetters.GET_NEW_NOTE,
      unit: UnitGetters.GET_UNIT,
      materialsTeamList: PartsRequestGetters.GET_MATERIALS_EMPLOYEES,
      uiConfig: LookupGetters.GET_UI_CONFIG,
      soSearchResults: ServiceOrderGetters.GET_SO_SEARCH_RESULTS
    }),
    serviceOrderId() {
      return this.serviceOrder.serviceOrderId;
    },
    unitId() {
      return this.unit.unitId;
    },
    notes() {
      var notes = this[NoteGetters.GET_NOTES];

      if (this.$isCustomer) {
        notes = notes.filter(note => !note.internal);
      }

      return notes;
    },
    filteredNotes() {
      const filtered =
        this.filterOptionSelected === 'Workflow Only' ? this.notes.filter(note => note.isWorkflow) : this.notes;
      return filtered;
    },
    myBranches() {
      return this.$store.getters[UserGetters.GET_USER_PROFILE].employeeBranches;
    },
    branchIsAccessible() {
      var branchId = this.serviceOrder.branchId;
      var myBranchIds = this.myBranches.map(branch => branch.branchId);

      return myBranchIds.includes(branchId);
    },
    vcpRecipientList() {
      return [...this.employeeList, ...this.vcpUserList];
    },
    partsRequestRecipientsList() {
      var list = [];
      var ids = this.employeeList.map(x => x.employeeId);
      this.materialsTeamList.forEach(x => {
        if (!ids.includes(x.employeeId)) {
          list.push(x);
        }
      });

      this.employeeList.forEach(x => {
        list.push(x);
      });

      return list;
    },
    message: {
      get() {
        return this.newNote.message;
      },
      set(value) {
        this.setData('message', value);
      }
    },
    isInternal: {
      get() {
        return this.newNote.isInternal;
      },
      set(value) {
        this.setData('isInternal', value);
      }
    },
    vcpRecipients: {
      get() {
        return this.newNote.vcpRecipients;
      },
      set(value) {
        this.setData('vcpRecipients', value);
      }
    },
    internalRecipients: {
      get() {
        return this.newNote.internalRecipients;
      },
      set(value) {
        this.setData('internalRecipients', value);
      }
    },
    externalRecipients: {
      get() {
        return this.newNote.externalRecipients;
      },
      set(value) {
        this.setData('externalRecipients', value);
      }
    },
    isCsrOrCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    },
    canBeExternal() {
      return (
        (this.$isCustomer || this.isCsrOrCsm) &&
        (this.uiConfig.showOpenSOs || this.serviceOrder.invoiced || this.serviceOrder.isEstimate)
      );
    },
    hasCustomersSelected() {
      return this.externalRecipients && this.externalRecipients.length > 0;
    }
  },
  created() {
    this.fetch();
    this.resetInternalFlag();
  },
  beforeDestroy() {
    this[NoteMutations.RESET_STATE]();
  },
  methods: {
    ...mapActions([
      NoteActions.FETCH_NOTES,
      NoteActions.POST_NOTE,
      PartsRequestActions.FETCH_MATERIALS_EMPLOYEES,
      LookupActions.FETCH_EMPLOYEES_BY_BRANCH
    ]),
    ...mapMutations([NoteMutations.SET_OLD_NOTE, NoteMutations.SET_NEW_NOTE, NoteMutations.RESET_STATE]),
    resetInternalFlag() {
      this.isInternal = !this.$isCustomer;

      //If VCP, we need to prevent the initial update of 'isInternal'
      //from being registered as an 'unsaved change.'
      this[NoteMutations.SET_OLD_NOTE](this.newNote);
    },
    async postNote() {
      try {
        this.$v.$touch();
        if (this.$v.$anyError) {
          ErrorService.createErrorToast(this, 'Error posting note. See indicated fields below.');
          return;
        }
        this.checkWarnings();
        if (!this.allowOverride) {
          this.allowOverride = true;
          return;
        }
        this.posting = this.loading = true;
        await this[NoteActions.POST_NOTE]({
          route: this.path,
          note: {
            internal: this.isInternal,
            message: this.message,
            recipients: [...this.internalRecipients, ...this.externalRecipients, ...this.vcpRecipients]
          }
        });
        this.resetNewNote();
        SuccessService.createSuccessToast(this.$root, 'Posted note successfully.');
      } catch {
        ErrorService.createErrorToast(this, 'Failed to post note.');
      } finally {
        this.posting = this.loading = false;
      }
    },
    resetNewNote() {
      this.resetInternalFlag();
      this.noRecipientsWarning = false;
      this.allowOverride = true;
      this.$v.$reset();
    },
    async fetch() {
      this.loading = true;
      await this[NoteActions.FETCH_NOTES]({
        route: this.path
      });
      if (this.branch) {
        await this[LookupActions.FETCH_EMPLOYEES_BY_BRANCH](this.branch);
      }
      this.loading = false;
    },
    checkWarnings() {
      if (!this.isInvoice) {
        const recipients = this.$isCustomer ? this.vcpRecipients : this.externalRecipients + this.internalRecipients;
        if ((!recipients || recipients.length === 0) && this.noRecipientsWarning == false) {
          this.noRecipientsWarning = true;
          this.allowOverride = false;
        }
      }
    },
    setData(key, newValue) {
      let value = { ...this.newNote };
      value[key] = newValue;
      this[NoteMutations.SET_NEW_NOTE](value);
    },
    getEmployee(empId) {
      return this.employeeList.find(employee => employee.employeeId === empId);
    },
    removeAssignedTo(assignedTo, recipients) {
      if (!assignedTo) {
        return recipients;
      }
      const assigned = this.getEmployee(assignedTo);
      return recipients.filter(recipients => recipients.emailAddress != assigned.emailAddress);
    },
    notifiedRecipients(recipients) {
      return recipients.filter(recipient => !recipient.primaryRecipient);
    },
    toRecipients(recipients) {
      const primary = recipients.filter(r => r.primaryRecipient);
      if (primary.length > 0) {
        return primary;
      }
      return recipients;
    }
  }
};
</script>
<style scoped>
.text-notification {
  color: #1a8fe8;
}
</style>
