<template>
  <div>
    <b-navbar
      v-if="$smallScreen"
      class="navbar-light bg-light shadow fixed-top header-nav mobile"
      style="z-index: 1039"
    >
      <b-nav class="w-100 d-flex justify-content-between align-items-center">
        <b-nav-item class="p-1">
          <div class="btn-icon" @click="goBack()">
            <font-awesome-icon icon="arrow-left" fixed-width />
            Back
          </div>
        </b-nav-item>
        <div>
          <input
            id="attachmentInput"
            type="file"
            hidden
            multiple
            :accept="supportedTypesList.join(',')"
            @input="inputFiles($event.target.files)"
          />
          <span v-if="numberOfAttachments > 0" class="text-primary px-1">({{ numberOfAttachments }})</span>
          <b-button variant="secondary" size="sm" :disabled="offline || uploadingFiles" @click="selectAttachment()">
            <div v-if="uploadingFiles" class="text-center px-1">
              <b-spinner small></b-spinner>
            </div>
            <font-awesome-icon v-else icon="camera" fixed-width size="lg" />
          </b-button>
        </div>
      </b-nav>
    </b-navbar>
    <b-card
      id="service-order-card"
      :class="$smallScreen ? 'rounded-0' : 'overflow-auto'"
      :style="`min-height: 90vh; ${$smallScreen ? '' : 'max-height: 90vh;'}`"
    >
      <div v-if="notFound">
        <p>Service Order not found.</p>
      </div>
      <div v-else-if="loading" class="text-center py-4">
        <b-spinner></b-spinner>
      </div>
      <div v-else>
        <h2 class="card-header">{{ isEstimate ? 'Estimate' : 'Service Order' }} Detail</h2>

        <b-row no-gutters>
          <b-col>
            <b-form-group
              :label="isEstimate ? 'Estimate ID:' : 'Service Order ID:'"
              label-for="serviceOrderId"
              label-class="col-head"
            >
              <div id="serviceOrderId">{{ serviceOrder.serviceOrderId || NA }}</div>
            </b-form-group>

            <b-form-group label="Fleet Unit ID:" label-for="fleetUnitId" label-class="col-head">
              <div id="fleetUnitId">{{ serviceOrder.fleetUnitId || NA }}</div>
            </b-form-group>

            <b-form-group label="Vin/Serial ID:" label-for="vin" label-class="col-head">
              <safe-hyperlink id="vin" :to="'/units/' + serviceOrder.unitId">
                {{ serviceOrder.vin }}
              </safe-hyperlink>
            </b-form-group>

            <b-form-group label="Customer:" label-for="customer" label-class="col-head">
              <div id="customer">{{ serviceOrder.customer.customerId }} - {{ serviceOrder.customer.customerName }}</div>
            </b-form-group>

            <b-form-group v-if="isEstimate" label="Reviewed:" label-for="reviewed" label-class="col-head">
              {{ serviceOrder.reviewed ? 'Yes' : 'No' }}
            </b-form-group>

            <b-form-group label="Assigned To:" label-for="assignTo" label-class="col-head">
              <employee-display :employee="serviceOrder.workflow.currentAssignment" :show-email="false" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
// components
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
// vuex
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { RootGetters } from '@/shared/store/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
// mixins
import UploadMixin from '@/shared/mixins/UploadMixin';

export default {
  name: 'ServiceOrderPage',
  components: {
    'employee-display': EmployeeDisplayComponent,
    'safe-hyperlink': SafeHyperlinkComponent
  },
  mixins: [UploadMixin],
  data() {
    return {
      NA: 'N/A',
      loading: false,
      notFound: false
    };
  },
  computed: {
    ...mapGetters({
      offline: RootGetters.GET_OFFLINE,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER
    }),
    unitId() {
      return this.$route.params.unitId;
    },
    serviceOrderId() {
      return this.$route.params.serviceOrderId;
    },
    attachmentInput() {
      return document.getElementById('attachmentInput');
    },
    numberOfAttachments() {
      return this.serviceOrder?.attachments?.filter(a => !a.dateRemoved).length;
    },
    isEstimate() {
      return this.serviceOrder.isEstimate;
    }
  },
  created: async function () {
    await this.loadPage();
  },
  methods: {
    ...mapActions([ServiceOrderActions.FETCH_SERVICE_ORDER]),
    ...mapMutations([ServiceOrderMutations.SET_ATTACHMENTS]),
    goBack() {
      this.$router.back();
    },
    async loadPage() {
      this.loading = true;
      try {
        await this[ServiceOrderActions.FETCH_SERVICE_ORDER]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderId
        });
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.notFound = true;
        }
      } finally {
        this.loading = false;
      }
    },
    selectAttachment() {
      this.attachmentInput.click();
    },
    async inputFiles(fileList) {
      const files = [...fileList];
      var attachments = await this.uploadFiles(files);
      this[ServiceOrderMutations.SET_ATTACHMENTS](attachments);
      this.attachmentInput.value = null;
    }
  }
};
</script>
