import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, SubletInvoiceActions, SubletInvoiceMutations } from './types';
import SubletInvoiceService from '@/shared/services/SubletInvoiceService';

const _actions = namespaceHelper.removeNamespace(namespace, SubletInvoiceActions);
const _mutations = namespaceHelper.removeNamespace(namespace, SubletInvoiceMutations);

export default {
  async [_actions.FETCH_SUBLET_INVOICE_LOOKUPS]({ commit }) {
    let response = await SubletInvoiceService.fetchSubletInvoiceLookups();
    const lookups = response.data;
    await commit(_mutations.SET_SUBLET_INVOICE_LOOKUPS, { lookups });
  },
  // Search
  async [_actions.SEARCH_SUBLET_INVOICES]({ commit }, parameters) {
    let response = {};
    response = await SubletInvoiceService.searchSubletInvoices(parameters);

    const searchResults = response.data;
    if (parameters.category == 'search') {
      await commit(_mutations.SET_SEARCH_SUBLET_INVOICES, response.data);
    } else {
      await commit(_mutations.SET_SUBLET_INVOICES, { searchResults, parameters });
    }
    return response.data;
  },
  async [_actions.FETCH_SUBLET_INVOICE]({ commit }, parameters) {
    const response = await SubletInvoiceService.fetchSubletInvoice(parameters);
    await commit(_mutations.SET_SUBLET_INVOICE, response.data);
    return response.data;
  },
  async [_actions.SAVE_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.saveSubletInvoice(invoice);
  },
  async [_actions.VALIDATE_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.validateSubletInvoice(invoice);
  },
  async [_actions.APPROVE_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.approveSubletInvoice(invoice);
  },
  async [_actions.REJECT_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.rejectSubletInvoice(invoice);
  },
  async [_actions.ADD_NEW_SUBLET_LINE_ITEM]({ commit }, params) {
    commit(_mutations.SET_NEW_SUBLET_LINE_ITEM, params);
  },
  async [_actions.REMOVE_SUBLET_LINE_ITEM]({ commit }, index) {
    commit(_mutations.REMOVE_SUBLET_LINE_ITEM, index);
  },
  async [_actions.REMOVE_SUBLET_INVOICE]({ commit }, index) {
    commit(_mutations.REMOVE_SUBLET_INVOICE, index);
  },
  async [_actions.DOWNLOAD_SUBLET_INVOICE_PDF](_, params) {
    let result = await SubletInvoiceService.fetchDownload(params);
    return result.data;
  }
};
