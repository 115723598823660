// components
import SearchEstimatesPage from '@/shared/pages/service-order/SearchEstimatesPage';
import InspectionCreatePage from '@/mobile/pages/InspectionCreatePage';
import InspectionPage from '@/shared/pages/inspection/InspectionPage';
import SearchInspectionsPage from '@/shared/pages/inspection/SearchInspectionsPage';
import LandingPage from '@/shared/pages/LandingPage';
import OperationsForecastPage from '@/shared/pages/operation-forecast/OperationsForecastPage';
import ServiceOrderPage from '@/mobile/pages/ServiceOrderPage';
import SearchServiceOrdersPage from '@/shared/pages/service-order/SearchServiceOrdersPage';
import UnitPage from '@/mobile/pages/UnitPage';
import SearchUnitsPage from '@/shared/pages/unit/SearchUnitsPage';
// vuex
import { UserActions } from '@/shared/store/user/types';
// helpers
import VueRouter from 'vue-router';
import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';

// Router constants.

const UNIT_PATH = '/units';
const UNIT_PAGE_PATH = UNIT_PATH + '/:unitId';
const INSPECTIONS_PATH = '/inspections';
const UNIT_INSPECTION_CREATE_PATH = UNIT_PAGE_PATH + '/inspections/create';
const UNIT_INSPECTION_PATH = INSPECTIONS_PATH + '/:unitInspectionId';
const SERVICE_ORDERS_SEARCH_PATH = '/service-orders';
const ESTIMATES_SEARCH_PATH = '/estimates';
const UNIT_SERVICE_ORDERS_PATH = UNIT_PAGE_PATH + '/service-orders';
const UNIT_SERVICE_ORDER_DETAILS_PATH = UNIT_SERVICE_ORDERS_PATH + '/:serviceOrderId?';
const OPERATION_FORECAST_PATH = '/operation-forecast';
const LOGIN_PATH = '/login';
const ERROR_PATH = '/error/:code?';
let DEFAULT_PATH = INSPECTIONS_PATH;

let routes = [
  {
    name: 'Inspections',
    path: INSPECTIONS_PATH,
    component: SearchInspectionsPage
  },
  {
    name: 'Create Inspection',
    path: UNIT_INSPECTION_CREATE_PATH,
    meta: { overrideHeader: Vue.$smallScreen },
    component: InspectionCreatePage
  },
  {
    name: 'Inspection',
    path: UNIT_INSPECTION_PATH,
    meta: { overrideHeader: Vue.$smallScreen },
    component: InspectionPage
  },
  { name: 'UnitSearch', path: UNIT_PATH, component: SearchUnitsPage },
  {
    name: 'Unit',
    path: UNIT_PAGE_PATH,
    component: UnitPage
  },
  { name: 'ServiceOrders', path: SERVICE_ORDERS_SEARCH_PATH, component: SearchServiceOrdersPage },
  { name: 'Estimates', path: ESTIMATES_SEARCH_PATH, component: SearchEstimatesPage },
  { name: 'OperationForecast', path: OPERATION_FORECAST_PATH, component: OperationsForecastPage },
  {
    name: 'ServiceOrderDetails',
    path: UNIT_SERVICE_ORDER_DETAILS_PATH,
    component: ServiceOrderPage
  },
  {
    name: 'Login',
    path: LOGIN_PATH,
    meta: { hideSidebar: true },
    component: LandingPage
  },
  // Default path for now
  {
    name: 'Default',
    path: '*',
    meta: { hideSidebar: true },
    redirect: DEFAULT_PATH
  },
  {
    name: 'Error',
    path: ERROR_PATH,
    component: LandingPage
  }
];

class AppRouter {
  constructor(store) {
    this.store = store;

    this.router = new VueRouter({
      mode: 'history',
      routes: routes
    });

    const vm = this;

    axios.interceptors.request.use(
      async function (config) {
        config.url = '/mobile' + config.url;
        config.paramsSerializer = params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        };

        const token = await vm.router.app.$msal.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token.idToken}`;
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const status = error.response?.status;
        if (status === 403) {
          vm.router.push({ name: 'Error', params: { code: status } });
          return Promise.reject(error);
        }

        if (status !== 401) {
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    this.router.beforeEach(async (to, from, next) => {
      try {
        if (to.name === 'Error') {
          next();
          return;
        }

        const msal = this.router.app.$msal;
        await msal.handleRedirectIfNecessary();
        const isAuthenticated = msal.isAuthenticated();

        if (to.fullPath === LOGIN_PATH) {
          if (to.params.logout) {
            await store.dispatch(UserActions.LOGOUT, false);
            await msal.signOut();
            next(false);
            return;
          }

          if (!isAuthenticated) {
            await msal.signIn();
          }

          next();
          return;
        }

        if (!isAuthenticated) {
          await msal.signIn();
          next();
          return;
        }

        next();
      } catch (error) {
        this.router.app.$msalError = true;
        this.router.app.$errorObject = error;
        next({ name: 'Error' });
      }
    });
  }
}

export default AppRouter;
