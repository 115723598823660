<template>
  <div>
    <b-card class="page-card">
      <div class="page-header">
        <h2>Inspections</h2>
      </div>
      <responsive-search
        ref="search"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        storage-key="inspectionSearch"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      />
      <h2 v-if="$isMobileApp" class="card-header border-0 m-0">Open Inspections</h2>
      <b-row v-if="!$smallScreen">
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pageSize"
          aria-controls="my-table"
          align="right"
          class="ml-auto w-auto"
        ></b-pagination>
      </b-row>
      <mobile-table v-if="$smallScreen" :items="inspections" :fields="fields">
        <template #[`name`]="{ item }">
          <safe-hyperlink v-if="hasCachedResponse(item)" :to="'inspections/' + item.unitInspectionId">
            {{ item.name }}
          </safe-hyperlink>
          <div v-else>
            <cloud-offline width="16" height="16" class="my-auto" />
            {{ item.name }}
          </div>
        </template>
        <template #[`customerId`]="{ item }">
          <label :class="`col-head`">Customer:</label>
          <safe-hyperlink :to="'/customers/' + item.customerId">
            {{ item.customerId }} - {{ item.customerName }}
          </safe-hyperlink>
        </template>
        <template #[`vin`]="{ item }">
          <label :class="`col-head`">VIN/Serial:</label>
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #[`serviceOrderId`]="{ item }">
          <label :class="`col-head`">Service Order ID:</label>
          <safe-hyperlink
            v-if="item.serviceOrderId"
            :to="'/units/' + item.unitId + '/service-orders/' + item.serviceOrderId"
          >
            {{ item.serviceOrderId }}
          </safe-hyperlink>
          <div v-else>N/A</div>
        </template>
        <template #[`branchId`]="{ item }">
          <label :class="`col-head`">Branch:</label>
          <div>{{ item.branchId }} - {{ item.branchName }}</div>
        </template>
        <template #[`dateCompleted`]="{ item }">
          <label :class="`col-head`">Completed:</label>
          <div>{{ item.dateCompleted | date }}</div>
        </template>
      </mobile-table>
      <b-table
        v-else
        class="border"
        striped
        sticky-header="63vh"
        no-border-collapse
        :items="inspections"
        :fields="fields"
        :per-page="pageSize"
        :current-page="currentPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No results found."
        empty-filtered-text="No results found."
        :tbody-tr-class="rowClass"
        @filtered="onFiltered"
      >
        <template #cell(name)="{ item }">
          <safe-hyperlink v-if="hasCachedResponse(item)" :to="'inspections/' + item.unitInspectionId">
            {{ item.name }}
          </safe-hyperlink>
          <div v-else>
            <cloud-offline width="16" height="16" class="mx-1 my-auto" />
            {{ item.name }}
          </div>
        </template>
        <template #cell(customerId)="{ item }">
          <safe-hyperlink :to="'/customers/' + item.customerId">
            {{ item.customerId }}
          </safe-hyperlink>
        </template>
        <template #cell(serviceOrderId)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </safe-hyperlink>
        </template>
        <template #cell(vin)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #cell(branchId)="{ item }">
          <div>{{ item.branchId }} - {{ item.branchName }}</div>
        </template>
        <template #cell(dateCompleted)="{ item }">
          <div>{{ item.dateCompleted | date }}</div>
        </template>

        <template #cell(actions)="{ item }">
          <div class="text-center">
            <b-dropdown
              size="xsm"
              right
              variant="secondary"
              no-caret
              boundary="viewport"
              :disabled="item.serviceOrderId != null"
            >
              <template #button-content>
                <font-awesome-icon icon="ellipsis-h" />
              </template>
              <b-dropdown-item v-if="!$isCustomer" @click="openServiceOrderAside(item)">
                Assign Service Order
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <transition v-if="$isVAMSApp" name="slide">
        <unit-service-order-history-aside
          v-if="showServiceOrderAside"
          v-model="serviceOrderWithJob"
          class="fixed-right fixed-top"
          header="Assign Service Order"
          :subheader="selectedInspection.name"
          :loading="asideIsBusy"
          selectable
          jobs-only
          :on-close="attemptCloseAside"
        >
          <div class="p-2 float-right">
            <b-button variant="primary" :disabled="assigning" @click="attemptAssignInspection">
              <div v-show="assigning">
                <b-spinner small></b-spinner>
                Assign...
              </div>
              <div v-show="!assigning">Assign</div>
            </b-button>
          </div>
        </unit-service-order-history-aside>
      </transition>
    </b-card>
    <unsaved-changes-modal
      v-if="!$smallScreen"
      ref="UnsavedChangesModal"
      message="You have unsaved changes. What would you like to do?"
      :continue-btn-text="`Continue With Assignment`"
      :has-changes="isServiceOrderSelected"
    ></unsaved-changes-modal>
    <unsaved-changes-modal
      v-if="!$smallScreen"
      ref="ConfirmAssignmentModal"
      title="Assign to Service Order"
      :continue-btn-text="`Assign`"
    >
      <p>
        Are you sure you want to assign
        <b>{{ selectedInspection.name }}</b>
        to SO ID#
        <router-link :to="'/units/' + selectedInspection.unitId + '/service-orders/' + selectedServiceOrderId">
          <span>{{ selectedServiceOrderId }}</span>
        </router-link>
        <span>?</span>
      </p>
    </unsaved-changes-modal>
  </div>
</template>
<script>
// components
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import CloudOfflineIcon from '@/shared/svg/CloudOfflineIcon';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
// vuex
import { RootGetters } from '@/shared/store/types';
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { InspectionActions } from '@/shared/store/inspection/types';
import { mapActions, mapGetters, mapState } from 'vuex';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

export default {
  name: 'InspectionsSearchPage',
  components: {
    'responsive-search': ResponsiveSearchComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'cloud-offline': CloudOfflineIcon,
    'unsaved-changes-modal': () => import('@/shared/components/UnsavedChangesModal'),
    'mobile-table': () => import('@/shared/components/MobileTableComponent'),
    'unit-service-order-history-aside': () => import('@/shared/components/service-order/UnitServiceOrderHistoryAside')
  },
  mixins: [SearchMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.search?.loadSearchParameters(to.query);
    this.runSearch();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isServiceOrderSelected()) {
      const closeThenNext = function (cont) {
        this.closeAside(cont);
        next(cont);
      };
      this.$refs.UnsavedChangesModal.show(this, closeThenNext.bind(this));
    } else {
      next();
    }
  },
  data: function () {
    return {
      pageSize: 50,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      sortBy: null,
      searchIsBusy: false,
      asideIsBusy: false,
      assigning: false,
      serviceOrderWithJob: null,
      selectedInspection: {},
      cachedInspections: null
    };
  },
  computed: {
    ...mapState(['inspection']),
    ...mapGetters({
      offline: RootGetters.GET_OFFLINE
    }),
    inspections() {
      return this.inspection.inspectionSearchResults;
    },
    fields() {
      const vin = { key: 'vin', label: 'VIN/Serial', sortable: true, collapsible: true };
      const fields = [];
      fields.push({ key: 'name', label: 'Inspection', sortable: true });
      fields.push({ key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true });
      if (!this.$smallScreen) fields.push(vin);
      fields.push({ key: 'customerId', label: 'Customer Id', sortable: true });
      if (!this.$smallScreen) fields.push({ key: 'customerName', label: 'Customer', sortable: true });
      if (this.$smallScreen) fields.push(vin);
      fields.push({ key: 'branchId', label: 'Branch', sortable: true, collapsible: true });
      if (!this.$isMobileApp)
        fields.push({ key: 'dateCompleted', label: 'Completed', sortable: true, collapsible: true });
      fields.push({ key: 'serviceOrderId', label: 'Service Order ID', sortable: true, collapsible: true });
      if (this.$isVAMSApp && !this.$smallScreen) fields.push({ key: 'actions', label: 'Actions' });
      return fields;
    },
    searchFields() {
      const searchFields = [];
      if (!this.$isMobileApp) {
        searchFields.push({
          key: 'completed',
          label: 'Status',
          radioOptions: [
            { text: 'All', value: 'false' },
            { text: 'Open', value: null },
            { text: 'Completed', value: 'true' }
          ]
        });
      }
      searchFields.push(
        ...[
          { ...this.fleetUnitIdField },
          { ...this.vinField, collapsible: true },
          { ...this.customerField },
          { ...this.branchField, collapsible: true },
          { ...this.serviceOrderIdField, collapsible: true }
        ]
      );
      return searchFields;
    },
    showServiceOrderAside() {
      return this.$store.getters[UnitGetters.GET_UNIT_SERVICE_ORDERS_ASIDE_SHOW];
    },
    selectedServiceOrderId() {
      return this.serviceOrderWithJob?.serviceOrder?.serviceOrderId;
    }
  },
  watch: {
    inspections() {
      this.checkInspectionCache();
    },
    offline() {
      this.checkInspectionCache();
    }
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    this.$router.replace({ query }).catch(() => {
      this.runSearch();
    });
  },
  methods: {
    ...mapActions([
      InspectionActions.FETCH_INSPECTIONS,
      InspectionActions.SEARCH_INSPECTIONS,
      InspectionActions.ASSIGN_SERVICE_ORDER,
      UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE,
      UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE,
      UnitActions.FETCH_UNIT,
      UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY
    ]),
    async checkInspectionCache() {
      const hasCache = await caches.has('api-cache');
      if (!hasCache) return;
      const cachedInspections = {};
      for (const inspection of this.inspections) {
        const request = 'mobile/api/inspections/' + inspection.unitInspectionId;
        const ci = await caches.match(request, { cacheName: 'api-cache' });
        if (ci) {
          cachedInspections[inspection.unitInspectionId] = true;
        }
      }
      this.cachedInspections = cachedInspections;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatBranch(item) {
      return `${item.branchId} - ${item.branchName}`;
    },
    async resetButtonClick() {
      this.filter = '';
      this.sortBy = '';
      this.searchButtonClick(null);
    },
    async searchButtonClick(query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    async runSearch() {
      try {
        this.searchIsBusy = true;
        await this[InspectionActions.SEARCH_INSPECTIONS](this.$route.query);
        this.totalRows = this.inspections.length;
        if (this.$isMobileApp && !this.offline && this.inspections.length > 0) {
          const unitInspections = this.inspections.slice(0, 10).map(i => {
            return { unitInspectionId: i.unitInspectionId, unitId: i.unitId };
          });
          this[InspectionActions.FETCH_INSPECTIONS](unitInspections);
        }
      } catch (error) {
        ErrorService.createErrorToast(this, 'Error searching Inspections.');
      } finally {
        this.searchIsBusy = false;
      }
    },
    async openServiceOrderAside(inspection) {
      this[UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE]();
      this.selectedInspection = inspection;
      await this.loadServiceOrderData(inspection.unitId);
    },
    attemptAssignInspection() {
      if (!this.serviceOrderWithJob) {
        ErrorService.createErrorToast(this, 'Please select an operation to assign.');
        return;
      }
      this.$refs.ConfirmAssignmentModal.show(this, null, this.assignInspection);
    },
    async assignInspection() {
      try {
        this.assigning = true;
        const unitInspectionId = this.selectedInspection.unitInspectionId;
        const serviceOrderId = this.serviceOrderWithJob.serviceOrder.serviceOrderId;
        const operationId = this.serviceOrderWithJob.selectedJob.operationId;
        await this[InspectionActions.ASSIGN_SERVICE_ORDER]({
          unitInspectionId,
          inspection: { serviceOrderId, operationId }
        });
        await this[InspectionActions.SEARCH_INSPECTIONS](this.$route.query);
        this.totalRows = this.inspections.length;
        this.closeAside();
        SuccessService.createSuccessToast(
          this.$root,
          `Assigned Service Order ID: ${serviceOrderId} to ${this.selectedInspection.name} successfully.`
        );
      } catch {
        ErrorService.createErrorToast(this, 'Error assigning service order to inspection.');
      } finally {
        this.assigning = false;
      }
    },
    isServiceOrderSelected() {
      return this.serviceOrderWithJob != null;
    },
    async attemptCloseAside() {
      if (this.isServiceOrderSelected()) {
        this.$refs.UnsavedChangesModal.show(this, this.closeAside);
      } else {
        await this.closeAside();
      }
    },
    async closeAside(cont = true) {
      if (cont) {
        await this[UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]();
        this.serviceOrderWithJob = null;
        this.selectedInspection = {};
      }
    },
    async loadServiceOrderData(unitId) {
      this.asideIsBusy = true;
      try {
        await this[UnitActions.FETCH_UNIT](unitId);
        await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({
          unitId,
          params: { jobsOnly: true, status: 'Open' }
        });
      } catch (error) {
        ErrorService.createErrorToast(this, 'Error fetching Service Order history.');
      } finally {
        this.asideIsBusy = false;
      }
    },
    hasCachedResponse(inspection) {
      // TODO move to safe-hyperlink
      if (!this.offline) return true;
      if (!this.cachedInspections || !inspection) return false;
      return this.cachedInspections[inspection.unitInspectionId];
    },
    rowClass(inspection) {
      if (!inspection) return '';
      return this.hasCachedResponse(inspection) ? '' : 'text-muted';
    }
  }
};
</script>
