import * as msal from '@azure/msal-browser';

const $msal = {
  install(Vue, msalConfig) {
    const msalInstance = new msal.PublicClientApplication(msalConfig);

    msalInstance.events = new Vue();

    // Register Callbacks for Redirect flow
    const handleRedirectPromise = msalInstance
      .handleRedirectPromise()
      .then(handleResponse)
      .catch(error => {
        msalInstance.events.$emit('login-error', error);
      });

    msalInstance.handleRedirectIfNecessary = async function () {
      return await handleRedirectPromise;
    };

    function handleResponse(resp) {
      if (resp !== null) {
        msalInstance.accountId = resp.account.homeAccountId;
        msalInstance.setActiveAccount(resp.account);
        msalInstance.events.$emit('login-success', resp.account);
      } else {
        const activeAccount = msalInstance.getAccount();
        if (!activeAccount) {
          return;
        } else {
          msalInstance.setActiveAccount(activeAccount);
          msalInstance.accountId = activeAccount.homeAccountId;
          msalInstance.events.$emit('login-success', activeAccount);
        }
      }
    }

    msalInstance.signIn = function () {
      return msalInstance
        .loginRedirect(msalConfig.loginRequest)
        .then(handleResponse)
        .catch(error => msalInstance.events.$emit('login-error', error));
    };

    msalInstance.signInPopup = function () {
      return msalInstance
        .loginPopup(msalConfig.loginRequest)
        .catch(error => msalInstance.events.$emit('login-error', error));
    };

    msalInstance.signOut = function () {
      const logoutRequest = {
        account: msalInstance.getAccount()
      };
      return msalInstance.logoutRedirect(logoutRequest);
    };

    msalInstance.getAccount = function () {
      if (msalInstance.accountId) return msalInstance.getAccountByHomeId(msalInstance.accountId);

      const currentAccounts = msalInstance.getAllAccounts();
      let currentAccount;
      if (!currentAccounts || currentAccounts.length < 1) {
        return null;
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
      } else if (currentAccounts.length === 1) {
        currentAccount = currentAccounts[0];
      }
      return currentAccount;
    };

    msalInstance.getToken = async function () {
      const request = msalConfig.loginRequest;
      request.account = msalInstance.getAccount();
      return await msalInstance.acquireTokenSilent(request).catch(async error => {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenPopup(request);
        } else {
          msalInstance.events.$emit('login-error', error);
          return;
        }
      });
    };

    msalInstance.isAuthenticated = function () {
      const currentAccount = msalInstance.getAccount();
      if (!currentAccount) return false;
      msalInstance.accountId = currentAccount.homeAccountId;
      const account = msalInstance.getAccountByHomeId(msalInstance.accountId);
      const timestamp = Math.floor(new Date().getTime() / 1000);
      return account && account.idTokenClaims && account.idTokenClaims.exp > timestamp;
    };

    Vue.prototype.$msal = msalInstance;
    Vue.msal = msalInstance;
  }
};

export default $msal;

// Create the main msalInstance instance
// configuration parameters are located at authConfig.js
