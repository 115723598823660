<template>
  <div
    id="side-nav"
    class="vams-side-nav"
    :class="`${sidebar === SIDEBAR.COLLAPSED ? 'collapsed' : 'expanded'} ${$smallScreen ? 'mobile' : ''}`"
    @mouseleave="navbarMouseLeave"
    @click="clickNav"
  >
    <div v-if="!$smallScreen" class="nav-collapse-icon-container" @click="toggleExpandCollapse()">
      <font-awesome-icon
        :icon="sidebar === SIDEBAR.COLLAPSED || mouseEnterExpanded ? 'angle-right' : 'angle-left'"
        class="nav-collapse-icon"
        size="2x"
      />
    </div>
    <b-nav class="d-flex flex-column h-100" vertical @mouseenter="navbarMouseEnter">
      <b-nav-item router-link :to="setNavPath('/inspections')" link-classes="mx-auto">
        <div>
          <img src="/images/inspection-white.svg" :width="width" :height="height" alt="Inspections" />
        </div>
        Inspections
      </b-nav-item>
      <b-nav-item router-link :to="setNavPath('/units')" link-classes="mx-auto">
        <div class="mb-1"><img src="/images/wheel.svg" :width="width" :height="height" alt="Units" /></div>
        Units
      </b-nav-item>
      <b-nav-item router-link :to="setNavPath('/estimates')" link-classes="mx-auto">
        <div class="mb-1">
          <img src="/images/calculator.svg" :width="width" :height="height" alt="Estimates" />
        </div>
        Estimates
      </b-nav-item>
      <b-nav-item router-link :to="setNavPath('/service-orders')" link-classes="mx-auto">
        <div class="mb-1">
          <img src="/images/clipboard-white.svg" :width="width" :height="height" alt="Service Orders" />
        </div>
        Service Orders
      </b-nav-item>
      <b-nav-item router-link :to="setNavPath('/operation-forecast')" link-classes="mx-auto">
        <div class="mb-1" :style="`width: ${width}; height: ${height};`">
          <font-awesome-icon icon="wrench" size="2x" />
        </div>
        Operation Forecast
      </b-nav-item>
      <b-nav-item v-if="$smallScreen && $environment" class="py-2 mt-auto border-0" link-classes="w-100">
        <h3>
          <b-badge variant="danger">{{ $environment }}</b-badge>
        </h3>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ConfigGetters } from '@/shared/store/config/types';
import { RootGetters, RootMutations } from '@/shared/store/types';
import { SIDEBAR } from '@/shared/store/state';

export default {
  name: 'SidebarNav',
  components: {},
  data: function () {
    return {
      SIDEBAR: SIDEBAR,
      mouseEnterExpanded: false,
      width: 30,
      height: 'auto'
    };
  },
  computed: {
    ...mapGetters([ConfigGetters.GET_CONFIG]),
    sidebar: {
      get() {
        return this.$store.getters[RootGetters.GET_SIDEBAR];
      },
      set(value) {
        this.$store.commit(RootMutations.SET_SIDEBAR, value);
      }
    }
  },
  methods: {
    toggleExpandCollapse() {
      if (this.mouseEnterExpanded) {
        this.sidebar = SIDEBAR.EXPANDED;
        this.mouseEnterExpanded = false;
      } else {
        this.sidebar = !this.sidebar;
      }
    },
    navbarMouseEnter() {
      if (this.sidebar === SIDEBAR.COLLAPSED) {
        this.sidebar = SIDEBAR.EXPANDED;
        this.mouseEnterExpanded = true;
      }
    },
    navbarMouseLeave() {
      if (this.mouseEnterExpanded) {
        this.sidebar = SIDEBAR.COLLAPSED;
        this.mouseEnterExpanded = false;
      }
    },
    clickNav() {
      if (this.$smallScreen) {
        this.sidebar = SIDEBAR.COLLAPSED;
      }
    },
    setNavPath(path) {
      const query = this.$route.path == path ? this.$route.query : null;
      return { path, query };
    }
  }
};
</script>
