import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, InvoiceMutations } from './types';
import { getDefaultState } from './state';
import Cloner from '@/shared/helpers/cloner';
import Vue from 'vue';

const _mutations = namespaceHelper.removeNamespace(namespace, InvoiceMutations);

export default {
  [_mutations.SET_FIRE_INVOICE_UI_CONFIG](state, uiConfig) {
    state.uiConfig = uiConfig;
  },
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_INVOICES](state, results) {
    var type = results.parameters.category;
    state.invoices[type] = results.searchResults;
  },
  [_mutations.SET_INVOICE](state, result) {
    state.invoice = result;
    state.dbInvoice = Cloner.deepClone(result);
  },
  [_mutations.SET_DB_INVOICE](state, result) {
    state.dbInvoice = result;
  },
  [_mutations.SET_HISTORY_INVOICES](state, result) {
    state.historyInvoices = result;
  },
  [_mutations.SET_REVIEW_INVOICES](state, result) {
    state.reviewInvoices = result;
  },
  [_mutations.SET_REASSIGN_INVOICES](state, result) {
    state.reassignInvoices = result;
  },
  [_mutations.SET_FILTERED_INVOICES](state, list) {
    state.filteredInvoices = list;
  },
  [_mutations.SET_INVOICE_OBJECTS](state, result) {
    result.objects.forEach(x => {
      state.invoiceObjects.push(x);
    });
  },
  [_mutations.CLEAR_INVOICE_OBJECTS](state) {
    state.invoiceObjects = [];
  },
  [_mutations.SET_AP_PROCESSORS](state, result) {
    state.apProcessors = result;
  },
  [_mutations.SET_DIVISION_AP_PROCESSORS](state, result) {
    state.divisionAPProcessors = result;
  },
  [_mutations.SET_INVOICE_APPROVERS](state, result) {
    state.approvers = result;
  },
  [_mutations.SET_INVOICE_DIVISION_APPROVERS](state, result) {
    state.divisionApprovers = result;
  },
  [_mutations.SET_TAX_JURISDICTIONS](state, result) {
    state.taxJurisdictions = result;
  },
  [_mutations.SET_VENDORS_LIST](state, list) {
    state.vendorsList = list;
  },
  [_mutations.SET_POS](state, pos) {
    state.pos = pos;
  },
  [_mutations.SET_FIRE_POS](state, firePOs) {
    state.firePOs = firePOs;
  },
  [_mutations.SET_APP_E_URL](state, url) {
    state.appEUrl = url;
  },
  [_mutations.SET_DAY](state, value) {
    state.day = value;
  },
  [_mutations.SET_MONTH](state, value) {
    state.month = value;
  },
  [_mutations.SET_YEAR](state, value) {
    state.year = value;
  },
  [_mutations.ADD_ORIGINAL_VENDOR](state) {
    state.vendorsList.push({ vendorId: state.invoice[0].vendorId, vendorName: state.invoice[0].vendorName });
  },
  [_mutations.SET_COMMON_PROP](state, { key, value }) {
    state.invoice.forEach(i => {
      i[key] = value;
    });
  },
  [_mutations.SET_LINEITEM_PROP](state, { index, key, value }) {
    Vue.set(state.invoice[index], key, value);
  },
  [_mutations.SET_DB_LINEITEM_PROP](state, { index, key, value }) {
    Vue.set(state.dbInvoice[index], key, value);
  },
  [_mutations.REMOVE_INVOICE](state, index) {
    Vue.delete(state.filteredInvoices, index);
  },
  [_mutations.SET_DEFAULT_GL_ACCOUNT](state, value) {
    state.defaultGLAccount = value;
  },
  [_mutations.IS_LOADING_MODAL](state, value) {
    state.loadingModal = value;
  },
  [_mutations.IS_FETCHING_POS](state, value) {
    state.fetchingPOs = value;
  },
  [_mutations.SET_NEW_LINE_ITEM](state, params) {
    var newItem = {
      accountNumber: params.commonInvoice.division === '1' ? null : params.commonInvoice.accountNumber,
      apProcessor: params.commonInvoice.apProcessor,
      approver: params.commonInvoice.approver,
      approverViewModel: params.commonInvoice.approverViewModel,
      batchName: params.commonInvoice.batchName,
      companyNumber: params.commonInvoice.companyNumber,
      checkDate: params.commonInvoice.checkDate,
      checkNumber: params.commonInvoice.checkNumber,
      checkType: params.commonInvoice.checkType,
      defaultTaxJurisdictiondivision: params.commonInvoice.defaultTaxJurisdiction,
      division: params.commonInvoice.division,
      description: null,
      docId: params.commonInvoice.docId,
      generalLedgerAmount: 0,
      invoiceAmount: params.commonInvoice.invoiceAmount,
      invoiceDate: params.commonInvoice.invoiceDate,
      invoiceDateTime: params.commonInvoice.invoiceDateTime,
      invoiceNumber: params.commonInvoice.invoiceNumber,
      itemNumber: params.newItemNumber,
      invoiceObjects: state.invoiceObjects,
      pages: params.commonInvoice.pages,
      poNumber: null,
      processorViewModel: params.commonInvoice.processorViewModel,
      receiverNumber: null,
      scanDate: params.commonInvoice.scanDate,
      status: params.commonInvoice.status,
      statusDate: params.commonInvoice.statusDate,
      taxJurisdiction: params.commonInvoice.defaultTaxJurisdiction,
      vendorId: params.commonInvoice.vendorId,
      vendorName: params.commonInvoice.vendorName,
      ctrl: params.commonInvoice.vendorId,
      xref: null
    };

    state.invoice.push(newItem);
    state.dbInvoice.push({});
  },
  [_mutations.REMOVE_LINE_ITEM](state, index) {
    Vue.delete(state.invoice, index);
  },
  [_mutations.UPDATE_LINE_ITEM_NUMBERS](state) {
    let startingInt = 10;
    let newItemInt;
    if (newItemInt == null) newItemInt = startingInt;
    state.invoice.forEach(inv => {
      let itemNumberString = newItemInt.toString().padStart(6, '0');
      inv.itemNumber = itemNumberString;
      newItemInt = newItemInt + 10;
    });
  },
  [_mutations.SET_OLD_VENDOR_ID](state, oldVendorId) {
    state.oldVendorId = oldVendorId;
  },
  [_mutations.IS_FREEZE_PDF](state, isFreezePdf) {
    state.isFreezePdf = isFreezePdf;
  },
  [_mutations.SET_IS_DUPLICATE_INVOICE](state, isDuplicateInvoice) {
    state.isDuplicateInvoice = isDuplicateInvoice;
  },
  [_mutations.SET_SELECTED_FIRE_PO](state, selectedFirePO) {
    state.selectedFirePO = selectedFirePO;
  },
  [_mutations.SET_FIRE_PO_ITEMS](state, firePOItems) {
    state.firePOItems = firePOItems;
  },
  [_mutations.SET_FIRE_PO_LINEITEM_PROP](state, { indexPO, indexReceiver, key, value }) {
    Vue.set(state.firePOs[indexPO].receivers[indexReceiver], key, value);
  },
  [_mutations.SET_INVOICES_COUNT](state, invoicesCount) {
    state.invoicesCount = invoicesCount;
  },
  [_mutations.TOGGLE_EXPAND_ALL](state, value) {
    state.expandAll = value;
  }
};
