export const getDefaultState = () => {
  return {
    subletInvoiceStatuses: [],
    subletInvoiceItemCategories: [],
    invoices: {},
    searchSubletInvoices: [],
    invoice: {},
    dbInvoice: {},
    filteredInvoices: [],
    isFreezePdf: false
  };
};
export default {
  ...getDefaultState()
};
