export const namespace = 'lookup/';

export const LookupGetters = {
  GET_LOOKUP_FETCH_DATE: namespace + 'getLookupFetchDate',
  GET_APPOINTMENT_STATUS_MAP: namespace + 'getAppointmentStatusMap',
  GET_APPOINTMENT_STATUS_LIST: namespace + 'getAppointmentStatusList',

  GET_AXLE_CONFIGURATIONS_LIST: namespace + 'getAxleConfigurationsList',

  GET_BREAKDOWN_BRANCHES_LIST: namespace + 'getBreakdownBranchesList',

  GET_BREAKDOWN_REASONS_MAP: namespace + 'getBreakdownReasonsMap',
  GET_BREAKDOWN_REASONS_LIST: namespace + 'getBreakdownReasonsList',

  GET_COMMENT_CODES_LIST: namespace + 'getCommentCodesList',
  GET_EMPLOYEE_LIST: namespace + 'getEmployeeList',

  GET_EPAY_TYPES_LIST: namespace + 'getEpayTypesList',
  GET_EPAY_STATUSES_LIST: namespace + 'getEpayStatusesList',

  GET_FUEL_TYPES_MAP: namespace + 'getFuelTypesMap',
  GET_FUEL_TYPES_LIST: namespace + 'getFuelTypesList',

  GET_INSPECTION_LIST: namespace + 'getInspectionList',

  GET_INSPECTION_RESULT_TYPES_MAP: namespace + 'getInspectionResultTypesMap',
  GET_INSPECTION_RESULT_TYPES_LIST: namespace + 'getInspectionResultTypesList',

  GET_JOB_OPERATION_STATUSES_MAP: namespace + 'getJobOperationStatusesMap',
  GET_JOB_OPERATION_STATUSES_FOR_ESTIMATE_LIST: namespace + 'getJobOperationStatusesForEstsimateList',

  GET_LOAD_RANGES_MAP: namespace + 'getLoadRangesMap',
  GET_LOAD_RANGES_LIST: namespace + 'getLoadRangesList',

  GET_LOCATIONS_MAP: namespace + 'getLocationsMap',
  GET_LOCATIONS_LIST: namespace + 'getLocationsList',

  GET_MILEAGE_RANGES_LIST: namespace + 'getMileageRangesList',

  GET_MOUNTED_EQUIPMENT_TYPES_MAP: namespace + 'getMountedEquipmentTypesMap',
  GET_MOUNTED_EQUIPMENT_TYPES_LIST: namespace + 'getMountedEquipmentTypesList',

  GET_MOUNTED_EQUIPMENT_CONTRACT_STATUSES_MAP: namespace + 'getMountedEquipmentContractStatusesMap',
  GET_MOUNTED_EQUIPMENT_CONTRACT_STATUSES_LIST: namespace + 'getMountedEquipmentContractStatusesList',

  GET_MY_ASSOCIATED_EMPLOYEES_MAP: namespace + 'getMyAssociatedEmployeesMap',
  GET_MY_ASSOCIATED_EMPLOYEES_LIST: namespace + 'getMyAssociatedEmployeesList',

  GET_MY_BRANCHES_MAP: namespace + 'myBranchesMap',
  GET_MY_BRANCHES_LIST: namespace + 'myBranchesList',

  GET_CUSTOMER_ID_NAME_LIST: namespace + 'getMyCustomerIdNamePairs',

  GET_MY_LOCATIONS_LIST: namespace + 'getMyLocationsList',

  GET_NON_SCHEDULED_OPERATIONS_LIST: namespace + 'getNonScheduledOperationsList',
  GET_OPERATION_STATUSES_LIST: namespace + 'getOperationStatusesList',
  GET_OPERATIONS_WITH_MATCHED_EXCEDE_JOBS: namespace + 'getOperationsWithMatchedExcedeJobs',

  GET_OWNERSHIP_TYPES_MAP: namespace + 'getOwnershipTypesMap',
  GET_OWNERSHIP_TYPES_LIST: namespace + 'getOwnershipTypesList',
  GET_PAD_MATERIAL_PERCENTS_MAP: namespace + 'getPadMaterialPercentsMap',
  GET_PAD_MATERIAL_PERCENTS_LIST: namespace + 'getPadMaterialPercentsList',
  GET_PARTS_REQUEST_TYPES_LIST: namespace + 'getPartsRequestTypesList',
  GET_PARTS_REQUEST_STATUS_LIST: namespace + 'getPartsRequestStatusList',
  GET_PARTS_REQUEST_STATUS_LIST_UNSORTED: namespace + 'getPartsRequestStatusListUnsorted',
  GET_PARTS_REQUEST_DELIVERY_METHODS_LIST: namespace + 'getpartsRequestDeliveryMethodsList',
  GET_DMV_STATES_MAP: namespace + 'getDmvStatesMap',
  GET_DMV_STATES_LIST: namespace + 'getDmvStatesList',

  GET_REPAIR_PRIORITIES_MAP: namespace + 'getRepairPrioritiesMap',
  GET_REPAIR_PRIORITIES_LIST: namespace + 'getRepairPrioritiesList',

  GET_REPAIR_REASONS_MAP: namespace + 'getRepairReasonsMap',
  GET_REPAIR_REASONS_LIST: namespace + 'getRepairReasonsList',

  GET_REPAIR_SITES_MAP: namespace + 'getRepairSitesMap',
  GET_REPAIR_SITES_LIST: namespace + 'getRepairSitesList',

  GET_REPAIR_STATUSES_MAP: namespace + 'getRepairStatusesMap',
  GET_REPAIR_STATUSES_LIST: namespace + 'getRepairStatusesList',

  GET_SALES_TYPES_MAP: namespace + 'getSalesTypesMap',
  GET_SALES_TYPES_LIST: namespace + 'getSalesTypesList',

  GET_SELECTABLE_REPAIR_STATUSES: namespace + 'getSelectableRepairStatuses',
  GET_SERVICE_ORDER_BRANCHES_LIST: namespace + 'getServiceOrderBranchesList',
  GET_ESTIMATE_BRANCHES_LIST: namespace + 'getEstimateBranchesList',
  GET_STANDARD_OPERATION_EXCEDE_JOBS_MAP: namespace + 'getUnitStandardOperationExcedeJob',
  GET_STANDARD_OPERATIONS_LIST: namespace + 'getStandardOperationsList',
  GET_STATES_AND_PROVINCES_LIST: namespace + 'getStatesAndProvincesList',
  GET_SUBLET_CODES_LIST: namespace + 'getSubletCodesList',
  GET_SUPPORTED_TYPES_LIST: namespace + 'getSupportedTypesList',

  GET_TREAD_DEPTHS_MAP: namespace + 'getTreadDepthsMap',
  GET_TREAD_DEPTHS_LIST: namespace + 'getTreadDepthsList',

  GET_TIRE_MANUFACTURERS_MAP: namespace + 'getTireManufacturersMap',
  GET_TIRE_MANUFACTURERS_LIST: namespace + 'getTireManufacturersList',

  GET_TIRE_REPLACEMENT_REASONS_MAP: namespace + 'getTireReplacementReasonsMap',
  GET_TIRE_REPLACEMENT_REASONS_LIST: namespace + 'getTireReplacementReasonsList',

  GET_TIRE_SIZES_MAP: namespace + 'getTireSizesMap',
  GET_TIRE_SIZES_LIST: namespace + 'getTireSizesList',

  GET_TIRE_TYPES_MAP: namespace + 'getTireTypesMap',
  GET_TIRE_TYPES_LIST: namespace + 'getTireTypesList',

  GET_BRAKE_STROKE_OPTIONS_MAP: namespace + 'getBrakeStrokeOptionsMap',
  GET_BRAKE_STROKE_OPTIONS_LIST: namespace + 'getBrakeStrokeOptionsList',

  GET_UNIT_STATUSES_MAP: namespace + 'unitStatusesMap',
  GET_UNIT_STATUSES_LIST: namespace + 'unitStatusesList',

  GET_UNIT_TYPES_MAP: namespace + 'unitTypesMap',
  GET_UNIT_TYPES_LIST: namespace + 'unitTypesList',

  GET_LOADING: namespace + 'getLoading',

  GET_UNIT_STATUS_DISPOSITIONS_MAP: namespace + 'getUnitStatusDispostionsMap',
  GET_UNIT_STATUS_DISPOSITIONS_LIST: namespace + 'getUnitStatusDispostionsList',
  GET_UNIT_LOCATIONS_LIST: namespace + 'getUnitLocationsList',
  GET_PRINT_FORMS_LIST: namespace + 'getPrintFormsList',

  GET_WARRANTY_STATUSES_MAP: namespace + 'getWarrantyStatusesMap',
  GET_WARRANTY_STATUSES_LIST: namespace + 'getWarrantyStatusesList',

  GET_WARRANTY_TYPES_MAP: namespace + 'getWarrantyTypesMap',
  GET_WARRANTY_TYPES_LIST: namespace + 'getWarrantyTypesList',

  GET_WARRANTY_SUBMISSION_TYPES_MAP: namespace + 'getWarrantySubmissionTypesMap',
  GET_WARRANTY_SUBMISSION_TYPES_LIST: namespace + 'getWarrantySubmissionTypesList',

  GET_WARRANTY_DOCUMENT_TYPES_MAP: namespace + 'getWarrantyDocumentTypesMap',
  GET_WARRANTY_DOCUMENT_TYPES_LIST: namespace + 'getWarrantyDocumentTypesList',

  GET_VMRS_HIERARCHY: namespace + 'getVMRSHierarcy',

  IS_FEATURE_FLAG_ENABLED: namespace + 'isFeatureFlagEnabled',
  GET_UI_CONFIG: namespace + 'getUIConfig'
};

export const LookupActions = {
  FETCH_LOOKUPS: namespace + 'fetchLookups',
  FETCH_EMPLOYEES_BY_BRANCH: namespace + 'fetchEmployeesByBranch',
  FETCH_VMRS_HIERARCHY: namespace + 'fetchVMRSHierarchy'
};

export const LookupMutations = {
  SET_LOOKUPS: namespace + 'setLookups',
  RESET_LOOKUPS: namespace + 'resetLookups',
  SET_MY_CUSTOMER_ID_NAME_PAIRS: namespace + 'setMyCustomerIdNamePairs',
  SET_EMPLOYEE_LIST: namespace + 'setEmployeeList',
  SET_STANDARD_OPERATIONS: namespace + 'setStandardOperations',
  SET_VMRS_HIERARCHY: namespace + 'setVMRSHierarchy',
  SET_LOADING: namespace + 'setLoading'
};
