import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, LookupGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, LookupGetters);

export default {
  [_getters.GET_LOADING]: state => {
    return state.loading;
  },
  [_getters.GET_LOOKUP_FETCH_DATE]: state => {
    return state.lookupFetchDate;
  },
  [_getters.GET_APPOINTMENT_STATUS_MAP]: state => {
    return state.lookups.appointmentStatusMap;
  },
  [_getters.GET_APPOINTMENT_STATUS_LIST]: state => {
    return Object.values(state.lookups.appointmentStatusMap);
  },
  [_getters.GET_AXLE_CONFIGURATIONS_LIST]: state => {
    return state.lookups.axleConfigurationsList;
  },
  [_getters.GET_BRAKE_STROKE_OPTIONS_MAP]: state => {
    return state.lookups.brakeStrokesMap;
  },
  [_getters.GET_BRAKE_STROKE_OPTIONS_LIST]: state => {
    return Object.values(state.lookups.brakeStrokesMap);
  },
  [_getters.GET_BREAKDOWN_BRANCHES_LIST]: state => {
    return state.lookups.customerBreakdownBranchesList;
  },
  [_getters.GET_BREAKDOWN_REASONS_MAP]: state => {
    return state.lookups.breakdownReasonsMap;
  },
  [_getters.GET_BREAKDOWN_REASONS_LIST]: state => {
    return Object.values(state.lookups.breakdownReasonsMap);
  },
  [_getters.GET_COMMENT_CODES_LIST]: state => {
    return state.lookups.commentCodesList;
  },
  [_getters.GET_DMV_STATES_MAP]: state => {
    return state.lookups.dmvStatesMap;
  },
  [_getters.GET_DMV_STATES_LIST]: state => {
    return Object.values(state.lookups.dmvStatesMap);
  },
  [_getters.GET_EPAY_TYPES_LIST]: state => {
    return state.lookups.ePayTypeList;
  },
  [_getters.GET_EPAY_STATUSES_LIST]: state => {
    return state.lookups.ePayStatusList;
  },
  // TODO switch to map?
  [_getters.GET_EMPLOYEE_LIST]: state => {
    return state.employeeList;
  },
  [_getters.GET_ESTIMATE_BRANCHES_LIST]: state => {
    return state.lookups.customerEstimateBranchesList;
  },
  [_getters.GET_FUEL_TYPES_MAP]: state => {
    return state.lookups.fuelTypesMap;
  },
  [_getters.GET_FUEL_TYPES_LIST]: state => {
    return Object.values(state.lookups.fuelTypesMap);
  },
  [_getters.GET_INSPECTION_RESULT_TYPES_MAP]: state => {
    return state.lookups.inspectionResultTypesMap;
  },
  [_getters.GET_INSPECTION_RESULT_TYPES_LIST]: state => {
    return Object.values(state.lookups.inspectionResultTypesMap);
  },
  [_getters.GET_INSPECTION_LIST]: state => {
    return [...state.lookups.inspectionsList].sort((a, b) => (a.name > b.name ? 1 : -1));
  },
  [_getters.GET_JOB_OPERATION_STATUSES_MAP]: state => {
    return state.lookups.jobOperationStatusesMap;
  },
  [_getters.GET_JOB_OPERATION_STATUSES_FOR_ESTIMATE_LIST]: state => {
    return Object.values(state.lookups.jobOperationStatusesMap).filter(s => s.isEstimateStatus);
  },
  [_getters.GET_LOAD_RANGES_MAP]: state => {
    return state.lookups.loadRangesMap;
  },
  [_getters.GET_LOAD_RANGES_LIST]: state => {
    return Object.values(state.lookups.loadRangesMap);
  },
  [_getters.GET_LOCATIONS_MAP]: state => {
    return state.lookups.locationsMap;
  },
  [_getters.GET_LOCATIONS_LIST]: state => {
    return Object.values(state.lookups.locationsMap).sort((a, b) => (a.description > b.description ? 1 : -1));
  },
  [_getters.GET_MILEAGE_RANGES_LIST]: state => {
    return state.lookups.mileageRangesList;
  },
  [_getters.GET_MOUNTED_EQUIPMENT_CONTRACT_STATUSES_MAP]: state => {
    return state.lookups.mountedEquipmentContractStatusesMap;
  },
  [_getters.GET_MOUNTED_EQUIPMENT_CONTRACT_STATUSES_LIST]: state => {
    return Object.values(state.lookups.mountedEquipmentContractStatusesMap).sort((a, b) => (a.name > b.name ? 1 : -1));
  },
  [_getters.GET_MOUNTED_EQUIPMENT_TYPES_MAP]: state => {
    return state.lookups.mountedEquipmentTypesMap;
  },
  [_getters.GET_MOUNTED_EQUIPMENT_TYPES_LIST]: state => {
    return Object.values(state.lookups.mountedEquipmentTypesMap).sort((a, b) => (a.name > b.name ? 1 : -1));
  },
  [_getters.GET_MY_ASSOCIATED_EMPLOYEES_MAP]: state => {
    return state.lookups.myAssociatedEmployeesMap;
  },
  [_getters.GET_MY_ASSOCIATED_EMPLOYEES_LIST]: state => {
    return Object.values(state.lookups.myAssociatedEmployeesMap);
  },
  [_getters.GET_MY_BRANCHES_MAP]: state => {
    return state.lookups.myBranchesMap;
  },
  [_getters.GET_MY_BRANCHES_LIST]: state => {
    return Object.values(state.lookups.myBranchesMap);
  },
  [_getters.GET_CUSTOMER_ID_NAME_LIST]: state => {
    return state.lookups.customerIdNameList;
  },
  [_getters.GET_MY_LOCATIONS_LIST]: state => {
    return [...state.lookups.myLocationsList].sort((a, b) => (a.description > b.description ? 1 : -1));
  },
  [_getters.GET_NON_SCHEDULED_OPERATIONS_LIST]: state => {
    return state.lookups.nonScheduledOperationsList;
  },
  [_getters.GET_OPERATION_STATUSES_LIST]: state => {
    return [...state.lookups.operationStatusesList].sort((a, b) => (a.description > b.description ? 1 : -1));
  },
  [_getters.GET_OWNERSHIP_TYPES_MAP]: state => {
    return state.lookups.ownershipTypesMap;
  },
  [_getters.GET_OWNERSHIP_TYPES_LIST]: state => {
    return Object.values(state.lookups.ownershipTypesMap);
  },
  [_getters.GET_PAD_MATERIAL_PERCENTS_MAP]: state => {
    return state.lookups.padMaterialPercentsMap;
  },
  [_getters.GET_PAD_MATERIAL_PERCENTS_LIST]: state => {
    return Object.values(state.lookups.padMaterialPercentsMap);
  },
  [_getters.GET_PARTS_REQUEST_TYPES_LIST]: state => {
    return state.lookups.partsRequestTypesList;
  },
  [_getters.GET_PARTS_REQUEST_STATUS_LIST]: state => {
    return state.lookups.partsRequestStatusList.sort((a, b) => (a.order > b.order ? 1 : -1));
  },
  //This is so we can use the list as a lookup list. If you try to filter or find on the sorted list,
  //there is a console warning about mutating the state outside of the mutations
  [_getters.GET_PARTS_REQUEST_STATUS_LIST_UNSORTED]: state => {
    return state.lookups.partsRequestStatusList;
  },
  [_getters.GET_PARTS_REQUEST_DELIVERY_METHODS_LIST]: state => {
    return state.lookups.partsRequestDeliveryMethodsList;
  },
  [_getters.GET_PRINT_FORMS_LIST]: state => {
    return [...state.lookups.printFormsList].sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
  },
  [_getters.GET_REPAIR_PRIORITIES_MAP]: state => {
    return state.lookups.repairPrioritiesMap;
  },
  [_getters.GET_REPAIR_PRIORITIES_LIST]: state => {
    return Object.values(state.lookups.repairPrioritiesMap).sort((a, b) => (a.id > b.id ? 1 : -1));
  },
  [_getters.GET_REPAIR_REASONS_MAP]: state => {
    return state.lookups.repairReasonsMap;
  },
  [_getters.GET_REPAIR_REASONS_LIST]: state => {
    return Object.values(state.lookups.repairReasonsMap).sort((a, b) => (a.id > b.id ? 1 : -1));
  },
  [_getters.GET_REPAIR_SITES_MAP]: state => {
    return state.lookups.repairSitesMap;
  },
  [_getters.GET_REPAIR_SITES_LIST]: state => {
    return Object.values(state.lookups.repairSitesMap).sort((a, b) => (a.id > b.id ? 1 : -1));
  },
  [_getters.GET_REPAIR_STATUSES_MAP]: state => {
    return state.lookups.repairStatusesMap;
  },
  [_getters.GET_REPAIR_STATUSES_LIST]: state => {
    return Object.values(state.lookups.repairStatusesMap).sort((a, b) => (a.id > b.id ? 1 : -1));
  },
  [_getters.GET_SELECTABLE_REPAIR_STATUSES]: (state, getters) => {
    var selectableRepairStatuses = getters[_getters.GET_REPAIR_STATUSES_LIST].filter(s => s.selectable);

    return selectableRepairStatuses;
  },
  [_getters.GET_SALES_TYPES_MAP]: state => {
    return state.lookups.salesTypesMap;
  },
  [_getters.GET_SALES_TYPES_LIST]: state => {
    return Object.values(state.lookups.salesTypesMap);
  },
  [_getters.GET_SERVICE_ORDER_BRANCHES_LIST]: state => {
    return state.lookups.customerServiceOrderBranchesList;
  },
  [_getters.GET_STANDARD_OPERATION_EXCEDE_JOBS_MAP]: state => {
    return state.lookups.standardOperationExcedeJobsMap;
  },
  [_getters.GET_STANDARD_OPERATIONS_LIST]: state => {
    return [...state.lookups.standardOperationsList].sort((a, b) => (a.name > b.name ? 1 : -1));
  },
  [_getters.GET_STATES_AND_PROVINCES_LIST]: state => {
    return state.lookups.statesAndProvincesList;
  },
  [_getters.GET_SUBLET_CODES_LIST]: state => {
    return state.lookups.subletCodesList;
  },
  [_getters.GET_SUPPORTED_TYPES_LIST]: state => {
    return state.lookups.supportedTypesList;
  },
  [_getters.GET_TIRE_REPLACEMENT_REASONS_MAP]: state => {
    return state.lookups.tireReplacementReasonsMap;
  },
  [_getters.GET_TIRE_REPLACEMENT_REASONS_LIST]: state => {
    return Object.values(state.lookups.tireReplacementReasonsMap);
  },
  [_getters.GET_TIRE_MANUFACTURERS_MAP]: state => {
    return state.lookups.tireManufacturersMap;
  },
  [_getters.GET_TIRE_MANUFACTURERS_LIST]: state => {
    return Object.values(state.lookups.tireManufacturersMap);
  },
  [_getters.GET_TIRE_SIZES_MAP]: state => {
    return state.lookups.tireSizesMap;
  },
  [_getters.GET_TIRE_SIZES_LIST]: state => {
    return Object.values(state.lookups.tireSizesMap);
  },
  [_getters.GET_TIRE_TYPES_MAP]: state => {
    return state.lookups.tireTypesMap;
  },
  [_getters.GET_TIRE_TYPES_LIST]: state => {
    return Object.values(state.lookups.tireTypesMap);
  },
  [_getters.GET_TREAD_DEPTHS_MAP]: state => {
    return state.lookups.treadDepthsMap;
  },
  [_getters.GET_TREAD_DEPTHS_LIST]: state => {
    return Object.values(state.lookups.treadDepthsMap);
  },
  [_getters.GET_UNIT_LOCATIONS_LIST]: state => {
    return state.lookups.customerUnitLocationsList;
  },
  [_getters.GET_UNIT_STATUS_DISPOSITIONS_MAP]: state => {
    return state.lookups.unitStatusDispositionsMap;
  },
  [_getters.GET_UNIT_STATUS_DISPOSITIONS_LIST]: state => {
    return Object.values(state.lookups.unitStatusDispositionsMap);
  },
  [_getters.GET_UNIT_STATUSES_MAP]: state => {
    return state.lookups.unitStatusesMap;
  },
  [_getters.GET_UNIT_STATUSES_LIST]: state => {
    return Object.values(state.lookups.unitStatusesMap).sort((a, b) => (a.description > b.description ? 1 : -1));
  },
  [_getters.GET_UNIT_TYPES_MAP]: state => {
    return state.lookups.unitTypesMap;
  },
  [_getters.GET_UNIT_TYPES_LIST]: state => {
    return Object.values(state.lookups.unitTypesMap).sort((a, b) => (a.description > b.description ? 1 : -1));
  },
  [_getters.GET_WARRANTY_DOCUMENT_TYPES_MAP]: state => {
    return state.lookups.warrantyDocumentTypesMap;
  },
  [_getters.GET_WARRANTY_DOCUMENT_TYPES_LIST]: state => {
    return Object.values(state.lookups.warrantyDocumentTypesMap);
  },
  [_getters.GET_WARRANTY_STATUSES_MAP]: state => {
    return state.lookups.warrantyStatusesMap;
  },
  [_getters.GET_WARRANTY_STATUSES_LIST]: state => {
    return Object.values(state.lookups.warrantyStatusesMap);
  },
  [_getters.GET_WARRANTY_SUBMISSION_TYPES_MAP]: state => {
    return state.lookups.warrantySubmissionTypesMap;
  },
  [_getters.GET_WARRANTY_SUBMISSION_TYPES_LIST]: state => {
    return Object.values(state.lookups.warrantySubmissionTypesMap);
  },
  [_getters.GET_WARRANTY_TYPES_MAP]: state => {
    return state.lookups.warrantyTypesMap;
  },
  [_getters.GET_WARRANTY_TYPES_LIST]: state => {
    return Object.values(state.lookups.warrantyTypesMap);
  },
  [_getters.GET_UI_CONFIG]: state => {
    return state.lookups.uiConfig;
  },
  [_getters.IS_FEATURE_FLAG_ENABLED]: state => feature => {
    const featureFlag = Object.entries(state.lookups.featureFlags).find(pair => pair[0] === feature);
    if (!featureFlag) return false;
    return featureFlag[1];
  },

  [_getters.GET_OPERATIONS_WITH_MATCHED_EXCEDE_JOBS]: state => operations => {
    for (const stdOp of operations) {
      const matchedStdOp = state.lookups.standardOperationsMap[stdOp.standardOperationId];
      const matchedExcedeJobs = [];
      for (let excedeJobId of matchedStdOp.excedeJobIds) {
        matchedExcedeJobs.push(state.lookups.standardOperationExcedeJobsMap[excedeJobId]);
      }
      stdOp.excedeJobOptions = matchedExcedeJobs;
    }
    return operations;
  },

  [_getters.GET_VMRS_HIERARCHY]: state => {
    return state.vmrs.hierarchy;
  }
};
