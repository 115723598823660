<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g fill="currentColor">
      <path
        d="M320.4,384H136c-14.6,0-28.5-2.6-41-7.2c-9.4-3.5-18-8.2-25.7-13.9c-11.5-8.5-20.8-19.3-27.2-31.9
		C35.7,318.5,32,304.1,32,288c0-12.1,1.8-22.8,5.1-32.3c2.4-7.1,5.7-13.6,9.6-19.5c5.9-8.8,13.3-16.4,22.2-22.7
		c8.8-6.3,18.9-11.4,30-15.1c8.4-2.8,12.9-11.9,10.1-20.3c-2.9-8.3-12-12.8-20.4-10c-12.3,4.1-23.9,9.7-34.6,16.7
		c-8,5.3-15.4,11.5-22,18.5c-9.9,10.5-18,23-23.5,37.3C3,254.8,0,270.7,0,288c0,18.7,3.8,36.2,10.9,51.9
		c5.3,11.7,12.3,22.4,20.7,31.8c12.7,14.1,28.5,25.1,46.2,32.7C95.6,412,115.3,416,136,416h184.4c8.8,0,16-7.2,16-16
		S329.2,384,320.4,384z"
      />
      <path
        d="M509.2,287.5c-3.2-13.5-9.1-25.3-16.7-35.3c-5.7-7.5-12.4-13.8-19.6-19.2c-10.9-8.1-22.9-14-35.2-18
		c-7.8-2.5-15.6-4.4-23.4-5.5c-3-19-8.4-36.5-15.7-52.4c-13.5-29.1-33.9-52.5-58.5-68.5c-24.7-16-53.6-24.5-84-24.5
		c-14.5,0-28.2,1.9-40.8,5.5c-12.7,3.5-24.4,8.6-35,14.9c-7.6,4.5-10.1,14.3-5.6,21.9c4.5,7.6,14.3,10.1,21.9,5.6
		c8.2-4.9,17.3-8.9,27.1-11.6c9.9-2.7,20.6-4.3,32.3-4.3c16.3,0,31.8,3,46.1,8.8c21.5,8.6,40.3,23.3,54.6,43.6
		c14.3,20.3,24.3,46.2,27.4,77.2c0.8,7.9,7.2,14,15.1,14.4c9.3,0.5,19.3,2.3,28.9,5.5c7.2,2.4,14.1,5.6,20.3,9.5
		c4.7,2.9,9,6.3,12.8,10.1c5.7,5.6,10.3,12.2,13.6,19.8c3.3,7.7,5.2,16.5,5.2,27.2c0,6.4-0.6,12.3-1.6,17.6
		c-1.6,8-4.2,14.8-7.7,20.6c-3.5,5.9-7.8,10.9-13,15.2c-6.8,5.6-7.7,15.7-2.1,22.5c5.5,6.7,15.6,7.6,22.4,2
		c5.2-4.3,10-9.3,14.3-14.8c6.3-8.3,11.3-17.8,14.6-28.4c3.3-10.6,5.1-22.3,5.1-34.8C512,303.4,511,295.2,509.2,287.5z"
      />
      <path
        d="M75.3,52.7c-6.2-6.2-16.4-6.2-22.6,0c-6.2,6.2-6.2,16.4,0,22.6l384,384c6.2,6.2,16.4,6.2,22.6,0s6.3-16.4,0-22.6L75.3,52.7
		z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloudOffline',
  props: {
    width: {
      type: [Number, String],
      default: 14
    },
    height: {
      type: [Number, String],
      default: 14
    }
  }
};
</script>
