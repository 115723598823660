export const getDefaultState = () => {
  return {
    dvirSearchResults: [],
    dvirsCount: null,
    dvirCountFetchDate: null
  };
};

export default {
  ...getDefaultState()
};
