import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, DeferredJobMutations } from './types';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, DeferredJobMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_DEFERRED_JOBS](state, deferredJobs) {
    state.deferredJobs = deferredJobs;
  },
  [_mutations.SET_DEFERRED_JOBS_COUNT](state, deferredJobsCount) {
    state.deferredJobsCountFetchDate = new Date();
    state.deferredJobsCount = deferredJobsCount;
  },
  [_mutations.SET_DEFERRED_JOBS_SEARCH_RESULTS](state, results) {
    state.deferredJobsSearchResults = results;
  },
  [_mutations.SET_UNIT_DEFERRED_JOBS](state, results) {
    state.unitDeferredJobs = results;
  },
  [_mutations.SET_SELECTED_DEFERRED_JOBS](state, deferredJobs) {
    state.selectedDeferredJobs = deferredJobs;
  }
};
