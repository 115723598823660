import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, DvirActions, DvirMutations } from './types';
import DvirService from '@/shared/services/DvirService';
import moment from 'moment';
const _actions = namespaceHelper.removeNamespace(namespace, DvirActions);
const _mutations = namespaceHelper.removeNamespace(namespace, DvirMutations);

export default {
  async [_actions.FETCH_DVIRS_COUNT]({ state, commit }) {
    if (state.dvirCountFetchDate && moment().isBefore(moment(state.dvirCountFetchDate).add(10, 'minutes'))) {
      return;
    }
    const count = await DvirService.getDvirsCount();
    commit(_mutations.SET_DVIRS_COUNT, count.data);
  },
  // Search
  async [_actions.SEARCH_DVIRS]({ commit }, parameters) {
    const response = await DvirService.searchDvirDefects(parameters);
    const searchResults = response.data;
    await commit(_mutations.SET_DVIR_SEARCH_RESULTS, searchResults);
    return searchResults;
  }
};
