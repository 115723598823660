import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import App from './App.vue';
import AppRouter from '@/mobile/AppRouter';
import '@/shared/helpers/font-awesome-component';
import '@/mobile/plugins/bootstrap-vue';
import '@/shared/helpers/vue-select-default';
import store from '@/shared/store';
import { ConfigMutations } from '@/shared/store/config/types';
import { RootMutations } from '@/shared/store/types';
import msalVue from '@/mobile/plugins/msal-vue';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';
import VamsFilters from '@/shared/helpers/filters';
import VueCurrencyInput from 'vue-currency-input';
import AxiosHelper from '@/shared/helpers/axios-helper';
import timeZoneHelper from '@/shared/helpers/time-zone-helper';
import VueAppInsights from 'vue-application-insights';
import '../registerServiceWorker';
import GlobalMixin from '@/shared/mixins/GlobalMixin';

timeZoneHelper.configureZoneAbbreviations();

axios.defaults.transformResponse = AxiosHelper.responseTransformers;

axios.get('/config.json').then(({ data }) => {
  Vue.prototype.$msalError = false;
  Vue.prototype.$errorObject = null;
  Vue.prototype.$isCustomer = false;
  Vue.prototype.$isMobileApp = true;
  Vue.prototype.$isVAMSApp = false;
  Vue.config.productionTip = true;
  Vue.prototype.$isIOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;

  Vue.use(VueRouter);
  Vue.use(Vuex);
  Vue.use(Vuelidate);
  Vue.use(Vue2Filters);
  Vue.use(VamsFilters);

  const currencyOptions = {
    globalOptions: { currency: 'USD', locale: 'en-US' }
  };
  Vue.use(VueCurrencyInput, currencyOptions);

  Vue.mixin(GlobalMixin);

  const msalConfig = data.mobile.msalConfig;
  msalConfig.auth.redirectUri = location.origin;
  msalConfig.auth.postLogoutRedirectUri = location.origin + '/login';

  try {
    Vue.use(msalVue, msalConfig);
  } catch (error) {
    Vue.prototype.$msalError = true;
    Vue.prototype.$errorObject = error;
  }

  store.commit(RootMutations.SET_ONLINE, navigator.onLine);

  store.commit(RootMutations.SET_SCREEN_WIDTH, document.body.clientWidth);

  window.addEventListener('resize', function () {
    store.commit(RootMutations.SET_SCREEN_WIDTH, document.body.clientWidth);
  });

  window.addEventListener('online', function () {
    store.commit(RootMutations.SET_ONLINE, true);
  });

  window.addEventListener('offline', function () {
    store.commit(RootMutations.SET_ONLINE, false);
  });
  store.commit(ConfigMutations.SET_CONFIG, data.mobile);

  const appRouter = new AppRouter(store).router;

  const instrumentationKey = data.mobile.applicationInsights.instrumentationKey;
  if (instrumentationKey) {
    Vue.use(VueAppInsights, {
      id: instrumentationKey,
      router: appRouter
    });
  }

  new Vue({
    router: appRouter,
    store: store,
    render: h => h(App)
  }).$mount('#app');
});
