export const getDefaultState = () => {
  return {
    deferredJobs: [],
    deferredJobsSearchResults: [],
    deferredJobsCount: null,
    unitDeferredJobs: [],
    selectedDeferredJobs: [],
    deferredJobsCountFetchDate: null
  };
};

export default {
  ...getDefaultState()
};
