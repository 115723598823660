export const getDefaultState = () => {
  const partsOrderPrototype = {
    partsOrderId: null,
    ePayRequests: []
  };

  return {
    partsOrderSearchResults: [],
    partsOrder: {
      ...partsOrderPrototype
    },
    dbPartsOrder: {
      ...partsOrderPrototype
    }
  };
};

export default {
  ...getDefaultState()
};
